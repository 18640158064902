import React, { useState } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { IconEye } from "@tabler/icons-react";
import ListPagination from "components/ListPagination"; // Assuming this is available

function ActionsDueAccounts({ data, handleView }) {
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const renderSortArrow = (column) => {
    if (sortColumn === column) {
      return sortDirection === "asc" ? <span>&#9650;</span> : <span>&#9660;</span>;
    }
    return null;
  };

  // Handle search functionality
  const filteredData = data.filter(
    (row) =>
      row.acctNo.toLowerCase().includes(searchValue.toLowerCase()) ||
      row.custName.toLowerCase().includes(searchValue.toLowerCase())
  );

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Sorting the data
  const sortedData = [...currentData].sort((a, b) => {
    const aValue = sortColumn ? a[sortColumn] : null;
    const bValue = sortColumn ? b[sortColumn] : null;
    if (sortDirection === "asc") {
      return aValue?.localeCompare ? aValue?.localeCompare(bValue) : aValue - bValue;
    } else {
      return bValue?.localeCompare ? bValue?.localeCompare(aValue) : bValue - aValue;
    }
  });

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
  }));

  return (
    <>
      <Typography sx={{ marginLeft: "3px" }} variant="h6">
        Actions Due Accounts
      </Typography>

      {/* Search bar */}
      <Stack direction="row" spacing={2} mb={1} justifyContent={"flex-end"}>
        <TextField
          label="Search"
          variant="outlined"
          disabled={!data?.length}
          size="small"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </Stack>

      <TableContainer
        sx={{ minWidth: "100%", border: "1px solid lightgray", maxHeight: "444px", boxShadow: 0 }}
      >
        <Table>
          <TableHead sx={{ display: "contents" }}>
            <TableRow
              className="NewTableHeader"
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 999,
                cursor: "pointer",
                userSelect: "none",
              }}
            >
              <TableCell
                className="NewTableHeaderFontColor"
                align="left"
                sx={{ fontWeight: "700" }}
                onClick={() => handleSort("acctNo")}
              >
                Account No {sortColumn === "acctNo" && renderSortArrow("acctNo")}
              </TableCell>
              <TableCell
                className="NewTableHeaderFontColor"
                sx={{ fontWeight: "700" }}
                align="left"
                onClick={() => handleSort("custName")}
              >
                Account Holder {sortColumn === "custName" && renderSortArrow("custName")}
              </TableCell>
              <TableCell
                className="NewTableHeaderFontColor"
                sx={{ fontWeight: "700" }}
                align="left"
                onClick={() => handleSort("segement")}
              >
                Category {sortColumn === "segement" && renderSortArrow("segement")}
              </TableCell>
              <TableCell
                className="NewTableHeaderFontColor"
                sx={{ fontWeight: "700" }}
                align="left"
                onClick={() => handleSort("outstand")}
              >
                Outstand {sortColumn === "outstand" && renderSortArrow("outstand")}
              </TableCell>
              <TableCell
                className="NewTableHeaderFontColor"
                sx={{ fontWeight: "700" }}
                align="left"
                onClick={() => handleSort("irregAmt")}
              >
                Irregular Amount {sortColumn === "irregAmt" && renderSortArrow("irregAmt")}
              </TableCell>
              <TableCell
                className="NewTableHeaderFontColor"
                sx={{ fontWeight: "700" }}
                align="left"
              >
                View
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.length ? (
              sortedData.map((row, index) => (
                <StyledTableRow key={index}>
                  <TableCell className="NewTableCells" align="left">
                    {row.acctNo}
                  </TableCell>
                  <TableCell className="NewTableCells" align="left">
                    {row.custName}
                  </TableCell>
                  <TableCell className="NewTableCells" align="left">
                    {row.segement}
                  </TableCell>
                  <TableCell className="NewTableCells" align="left">
                    {row.outstand}
                  </TableCell>
                  <TableCell className="NewTableCells" align="left">
                    {row.irregAmt}
                  </TableCell>
                  <TableCell className="NewTableCells" align="left">
                    <Button onClick={() => handleView(row.acctNo, row.newIrac)}>
                      <IconEye style={{ marginRight: "3px" }} />
                      View
                    </Button>
                  </TableCell>
                </StyledTableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>No Data</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Component */}
      <Stack direction="row" justifyContent="flex-end" mt={2}>
        <ListPagination
          count={totalPages}
          page={currentPage}
          variant="outlined"
          shape="rounded"
          onChange={(event, value) => setCurrentPage(value)}
          color="primary"
        />
      </Stack>
    </>
  );
}

export default ActionsDueAccounts;
