import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { forwardRef, useEffect, useState } from "react";
import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
  Typography,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useLocation } from "react-router-dom";
// date converter library
import moment from "moment";
// toast messages
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "components/Loader";
import MsmeForm from "./Inpections/MsmeForm";
import AgricultureForm from "./Inpections/AgricultureForm";
import PSegmentForm from "./Inpections/PSegmentForm";
import { token } from "utils/userToken";

// new interview modal transition
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Inspection() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const route = useLocation().pathname.split("/").slice(1);
  const id = route[route.length - 1];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // MODAL HANDLERS AND STATE
  const [modalOpen, setModalopen] = useState(false);
  const [agriModalOpen, setAgriModalopen] = useState(false);
  const [msmeModalOpen, setMsmeModalopen] = useState(false);
  const createNewInspection = (type) => {
    if (type === "agri") {
      setAgriModalopen(true);
    } else if (type === "pSegment") {
      setModalopen(true);
    } else if (type === "msme") {
      setMsmeModalopen(true);
    }
  };
  // const agriModalClose = () => {
  //   setAgriModalopen(false);
  // };

  // Initial form data
  const initialFormData = {
    name: "",
    residenceAddress: "",
    residenceVisited: "no",
    residenceObservations: "",
    workPlaceAddress: "",
    workPlaceVisited: "no",
    workPlaceObservations: "",
    residenceImages: [],
    workPlaceImages: [],
    residenceGeoLocation: "",
    workPlaceGeoLocation: "",
  };
  const initialAgriData = {
    name: "",
    residenceAddress: "",
    residenceVisited: "no",
    residenceObservations: "",
    purposeOfVisit: "",
    otherLocationAddress: "",
    otherLocationObservations: "",
    residenceGeoLocation: "",
    otherLocationGeoLocation: "",
    inspectionImages: [],
  };
  const initialMsmeData = {
    dateOfVisit: "",
    purposeOfVisit: "",
    nameOfPersonInterviewed: "",
    commentOnActivityOfFirm: "",
    commentsAboutGeneralWorkingCompany: "",
    dataCollectionDone: "",
    inspectionConductedBy: "",
    limits: [],
  };

  // FORM DATA HANDLERS AND STATES
  const [formData, setFormData] = useState(initialFormData);
  const [agriFormData, setAgriFormData] = useState(initialAgriData);
  const [msmeFormData, setMsmeFormData] = useState(initialMsmeData);

  // validations
  const [msmeErrors, setMsmeErrors] = useState({});
  const validateMsmeForm = () => {
    const newErrors = {};
    let isValid = true;
    if (!msmeFormData.dateOfVisit.trim()) {
      newErrors.dateOfVisit = "Date of Visit is required";
      isValid = false;
    }
    if (!msmeFormData.purposeOfVisit.trim()) {
      newErrors.purposeOfVisit = "Purpose of Visit is required";
      isValid = false;
    }
    if (!msmeFormData.nameOfPersonInterviewed.trim()) {
      newErrors.nameOfPersonInterviewed = "Person name is required";
      isValid = false;
    }
    if (!msmeFormData.inspectionConductedBy.trim()) {
      newErrors.inspectionConductedBy = "Person name is required";
      isValid = false;
    }
    setMsmeErrors(newErrors);
    return isValid;
  };
  const [agriErrors, setAgriErrors] = useState({});
  const validateAgriForm = () => {
    const newErrors = {};
    let isValid = true;
    if (!agriFormData.name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (!agriFormData.residenceVisited.trim()) {
      newErrors.residenceVisited = "Please select an option";
      isValid = false;
    }
    if (agriFormData.residenceVisited === "yes") {
      if (!agriFormData.residenceObservations.trim()) {
        newErrors.residenceObservations = "Observations are required";
        isValid = false;
      }
      if (!agriFormData.residenceAddress.trim()) {
        newErrors.residenceAddress = "Residence address is required";
        isValid = false;
      }
      if (!agriFormData.purposeOfVisit.trim()) {
        newErrors.purposeOfVisit = "Purpose of visit is required";
        isValid = false;
      }
    }

    setAgriErrors(newErrors);
    return isValid;
  };
  const [pSegmentErrors, setPsegmentErrors] = useState({});
  const validatePsegmentForm = () => {
    const newErrors = {};
    let isValid = true;
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }
    if (formData.residenceVisited === "yes") {
      if (!formData.residenceAddress.trim()) {
        newErrors.residenceAddress = "Provide residence address";
        isValid = false;
      }
      if (!formData.residenceObservations.trim()) {
        newErrors.residenceObservations = "Provide Observation";
        isValid = false;
      }
    }
    if (!formData.residenceVisited.trim()) {
      newErrors.residenceVisited = "Select Option";
      isValid = false;
    }
    if (formData.workPlaceVisited === "yes") {
      if (!formData.workPlaceAddress.trim()) {
        newErrors.workPlaceAddress = "Provide Workplace Address";
        isValid = false;
      }
      if (!formData.workPlaceObservations.trim()) {
        newErrors.workPlaceObservations = "Provide Observation";
        isValid = false;
      }
    }
    if (!formData.workPlaceVisited.trim()) {
      newErrors.workPlaceVisited = "Select Option";
      isValid = false;
    }
    setPsegmentErrors(newErrors);
    return isValid;
  };

  // Handler for form submission
  const handleSubmit = async () => {
    const valid = validatePsegmentForm();
    if (valid) {
      const pSegment = process.env.REACT_APP_BACKEND_URL + "/Psegement_inspections/create";
      try {
        const res = await axios({
          method: "post",
          url: pSegment,
          data: { ...formData, accountNumber: id },
          headers: { Authorization: `Bearer ${token}` },
        });
        if (res.status === 200) {
          setFormData(initialFormData);
          toast.success("Inspection Saved");
          getInspectionHistory();
        }
      } catch (error) {
        toast.error("Unable to save inspection");
      }
      setFormData(initialFormData);
      pSegmentFormModalClose();
    }
  };
  const handleAgriSubmit = async () => {
    const valid = validateAgriForm();
    if (valid) {
      const agriUrl = process.env.REACT_APP_BACKEND_URL + "/Agriculture_inspections/create";
      try {
        const res = await axios({
          method: "post",
          url: agriUrl,
          data: { ...agriFormData, accountNumber: id },
          headers: { Authorization: `Bearer ${token}` },
        });
        if (res.status === 200) {
          setAgriFormData(initialAgriData);
          toast.success("Inspection Saved");
          getInspectionHistory();
        }
      } catch (error) {
        console.log(error.message);
        toast.error("Unable to save inspection");
      }
      agriFormModalClose();
    }
  };
  const handleMsmeSubmit = async () => {
    const valid = validateMsmeForm();
    if (valid) {
      const msmeUrl = backendUrl + "/Msme_inspections/create";
      try {
        const res = await axios({
          method: "post",
          url: msmeUrl,
          data: { ...msmeFormData, accountNumber: id },
          headers: { Authorization: `Bearer ${token}` },
        });
        if (res.status === 200) {
          setMsmeFormData(initialMsmeData);
          toast.success("Inspection Saved");
          getInspectionHistory();
        }
      } catch (error) {
        console.log(error.message);
        toast.error("Unable to save inspection");
      }
      msmeFormModalClose();
    }
  };

  // Handler for closing the modal
  const pSegmentFormModalClose = () => {
    // setFormData(initialFormData);
    setModalopen(null);
    setPsegmentErrors({});
  };
  const agriFormModalClose = () => {
    // setFormData(initialFormData);
    setAgriModalopen(null);
    setAgriErrors({});
  };
  const msmeFormModalClose = () => {
    // setMsmeFormData(initialMsmeData);
    setMsmeModalopen(null);
    setMsmeErrors({});
  };

  // ----------------------

  const getInspectionHistory = async () => {
    const url = backendUrl + `/Inspections/getAll?accountNumber=${id}`;
    try {
      const res = await axios({
        method: "get",
        url: url,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setData(res?.data?.data);
      }
    } catch (error) {
      console.log(error?.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const getAccountDetails = async () => {
    const url = backendUrl + `/account/getName?acctNo=${id}`;
    const res = await axios.get(url);
    setFormData((prev) => ({ ...prev, name: res.data.data }));
    setMsmeFormData((prev) => ({ ...prev, nameOfPersonInterviewed: res.data.data }));
    setAgriFormData((prev) => ({ ...prev, name: res.data.data }));
  };
  useEffect(() => {
    getAccountDetails();
    getInspectionHistory();
  }, []);
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
    // hide last border
  }));
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox sx={{ mt: 3 }}>
          <Typography sx={{ marginLeft: "3px", textAlign: "center" }} variant="h4">
            Inspection History
          </Typography>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 220px)",
              boxShadow: 0,
              border: "1px solid lightgray",
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ width: "100%", display: "contents" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    backgroundColor: "#49a3f1",
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                  }}
                >
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    A/C No
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Inspection Type
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Date
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Location
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Summary
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Report
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length ? (
                  data?.map((row, index) => (
                    <StyledTableRow key={index}>
                      <TableCell className="NewTableCells" align="left">
                        {row?.accountNumber ? row?.accountNumber : "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row?.followUpType ? row?.followUpType : "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.date ? moment(row?.date).format("DD-MM-YYYY hh:mm") : "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row?.location ? row?.location : "---"}
                      </TableCell>
                      <TableCell
                        className="NewTableCells"
                        align="left"
                        sx={{ maxWidth: "200px", overflow: "hidden" }}
                      >
                        <div style={{ maxWidth: "220px", wordWrap: "break-word" }}>
                          {row.summary || "---"}
                        </div>
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.report ? (
                          <a href={row.report} download>
                            <Button>Download</Button>
                          </a>
                        ) : (
                          "---"
                        )}
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack justifyContent="flex-end" my={1} gap={1} flexDirection="row">
            <Button
              className="ActionButtonsColor"
              variant="contained"
              onClick={() => createNewInspection("agri")}
              sx={{ color: "white !important" }}
            >
              Agriculture
            </Button>
            <Button
              className="ActionButtonsColor"
              variant="contained"
              onClick={() => createNewInspection("pSegment")}
              sx={{ color: "white !important" }}
            >
              p-segment
            </Button>
            <Button
              className="ActionButtonsColor"
              variant="contained"
              onClick={() => createNewInspection("msme")}
              sx={{ color: "white !important" }}
            >
              MSME
            </Button>
          </Stack>
        </MDBox>
      )}
      {/* p-segment */}
      <Dialog
        open={Boolean(modalOpen)}
        TransitionComponent={Transition}
        keepMounted
        onClose={pSegmentFormModalClose}
        maxWidth="md"
        fullWidth
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Record new inspection"}</DialogTitle>
        <DialogContent sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
          <PSegmentForm
            formData={formData}
            backendUrl={backendUrl}
            setFormData={setFormData}
            pSegmentErrors={pSegmentErrors}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleSubmit}>Submit</Button>
          <Button onClick={pSegmentFormModalClose}>Cancel</Button>
          {/* <Button onClick={handleGenerateReport}>Generate Report</Button> */}
        </DialogActions>
      </Dialog>
      {/* Agriculture */}
      <Dialog
        open={Boolean(agriModalOpen)}
        TransitionComponent={Transition}
        keepMounted
        onClose={agriFormModalClose}
        maxWidth="md"
        fullWidth
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Record new inspection"}</DialogTitle>
        <DialogContent sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
          <AgricultureForm
            agriFormData={agriFormData}
            setAgriFormData={setAgriFormData}
            agriErrors={agriErrors}
          />
        </DialogContent>
        {/* agriculture form  */}
        <DialogActions>
          <Button onClick={handleAgriSubmit}>Submit</Button>
          <Button onClick={agriFormModalClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {/* msme form */}
      <Dialog
        open={Boolean(msmeModalOpen)}
        TransitionComponent={Transition}
        keepMounted
        onClose={msmeFormModalClose}
        maxWidth="md"
        fullWidth
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Record new inspection"}</DialogTitle>
        <DialogContent sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
          <MsmeForm
            msmeErrors={msmeErrors}
            setMsmeFormData={setMsmeFormData}
            msmeFormData={msmeFormData}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMsmeSubmit}>Submit</Button>
          <Button onClick={msmeFormModalClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}
