import React, { useState } from "react";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Stack,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import axios from "axios";
import { token } from "utils/userToken";

const AgricultureForm = ({ agriFormData, setAgriFormData, agriErrors }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleInputChange = (field, value, reportType) => {
    setAgriFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const getGeolocation = (segment, locationType) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          if (locationType === "residence") {
            setAgriFormData((prevData) => ({
              ...prevData,
              residenceGeoLocation: `Latitude: ${latitude}, Longitude: ${longitude}`,
            }));
          } else {
            setAgriFormData((prevData) => ({
              ...prevData,
              otherLocationGeoLocation: `Latitude: ${latitude}, Longitude: ${longitude}`,
            }));
          }
        },
        (error) => {
          console.error(`Geolocation error: ${error.message}`);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser");
    }
  };
  const handleAgriImageUpload = async (field, e) => {
    const url = backendUrl + "/Agriculture_inspections/uploadImages";
    const receivedFiles = e.target.files;
    try {
      const body = new FormData();
      for (let i = 0; i < receivedFiles.length; i++) {
        body.append("files", receivedFiles[i]);
      }
      const res = await axios.post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        toast.success(res?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }

    const fileNames = Array.from(receivedFiles).map((file) => file.name);
    setAgriFormData((prevData) => ({
      ...prevData,
      inspectionImages: [...prevData.inspectionImages, ...fileNames],
    }));
  };
  return (
    <>
      <TextField
        label="Name"
        fullWidth
        sx={{ mt: 1 }}
        value={agriFormData.name}
        onChange={(e) => handleInputChange("name", e.target.value, "agri")}
        required
        error={!!agriErrors.name}
        helperText={agriErrors.name ? agriErrors.name : ""}
      />
      <TextField
        select
        label="Residence Visited"
        fullWidth
        sx={{ height: "45px" }}
        value={agriFormData.residenceVisited}
        onChange={(e) => handleInputChange("residenceVisited", e.target.value, "agri")}
        required
        error={!!agriErrors.residenceVisited}
        helperText={agriErrors.residenceVisited ? agriErrors.residenceVisited : ""}
        SelectProps={{
          native: true, // Use native select element
        }}
      >
        <option value="yes">Yes</option>
        <option value="no">No</option>
      </TextField>
      {agriFormData.residenceVisited === "yes" && (
        <>
          <TextField
            label="Residence Address"
            fullWidth
            value={agriFormData.residenceAddress}
            onChange={(e) => handleInputChange("residenceAddress", e.target.value, "agri")}
            required
            error={!!agriErrors.residenceAddress}
            helperText={agriErrors.residenceAddress ? agriErrors.residenceAddress : ""}
          />
          <TextField
            label="Observations"
            fullWidth
            multiline
            rows={4}
            value={agriFormData.residenceObservations}
            onChange={(e) => handleInputChange("residenceObservations", e.target.value, "agri")}
            required
            error={!!agriErrors.residenceObservations}
            helperText={agriErrors.residenceObservations ? agriErrors.residenceObservations : ""}
          />
          <TextField
            label="Purpose of Visit"
            fullWidth
            value={agriFormData.purposeOfVisit}
            onChange={(e) => handleInputChange("purposeOfVisit", e.target.value, "agri")}
            required
            error={!!agriErrors.purposeOfVisit}
            helperText={agriErrors.purposeOfVisit ? agriErrors.purposeOfVisit : ""}
          />
        </>
      )}
      <TextField
        label="Address of Other Location Visited"
        fullWidth
        value={agriFormData.otherLocationAddress}
        onChange={(e) => handleInputChange("otherLocationAddress", e.target.value, "agri")}
      />
      <TextField
        label="Observations (if any)"
        fullWidth
        multiline
        rows={4}
        value={agriFormData.otherLocationObservations}
        onChange={(e) => handleInputChange("otherLocationObservations", e.target.value, "agri")}
      />
      <Stack flexDirection="row" gap="10px" alignItems="center">
        <TextField
          label="Residence Geo Location"
          fullWidth
          value={agriFormData.residenceGeoLocation}
          disabled
          // onChange={(e) =>
          //   handleAgriInputChange("residenceGeoLocation", e.target.value, "agriFormData")
          // }
        />
        <Button
          variant="contained"
          sx={{ color: "white !important" }}
          onClick={() => getGeolocation("agri", "residence")}
        >
          Capture
        </Button>
      </Stack>
      <Stack flexDirection="row" gap="10px" alignItems="center">
        <TextField
          label="Other Inspection Location"
          fullWidth
          disabled
          value={agriFormData.otherLocationGeoLocation}
          // onChange={(e) =>
          //   handleAgriInputChange("otherLocationGeoLocation", e.target.value, "agriFormData")
          // }
        />
        <Button
          variant="contained"
          sx={{ color: "white !important" }}
          onClick={() => getGeolocation("agri", "else")}
        >
          Capture
        </Button>
      </Stack>
      <Stack>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="inspection-images-upload"
          type="file"
          onChange={(e) => handleAgriImageUpload("inspectionImages", e)}
          multiple
        />
        <label htmlFor="inspection-images-upload">
          <Button sx={{ color: "white !important" }} variant="contained" component="span">
            Upload Images
          </Button>
        </label>
      </Stack>
      {!!agriFormData?.inspectionImages?.length && (
        <>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Index</TableCell>
                <TableCell>File Name</TableCell>
              </TableRow>
              {agriFormData?.inspectionImages?.length ? (
                agriFormData.inspectionImages.map((file, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{file}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2}>No Data</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </>
      )}
    </>
  );
};

export default AgricultureForm;
