import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { TextField, Stack, Typography, Box, CircularProgress } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import background from "../../../assets/Bg/ruppeBG.jpg";
import { backendUrl } from "utils/env";
import { toast } from "react-toastify";
import axios from "axios";
import { setToken } from "utils/userToken";

function SignIn() {
  const navigate = useNavigate();

  // React Hook Form setup
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  // Login logic
  const onSubmit = async (data) => {
    const url = backendUrl + "/user/authenticate";
    try {
      const response = await axios.post(url, data);
      sessionStorage.setItem("mail", response?.data?.data?.mail);
      sessionStorage.setItem("password", response?.data?.data?.username);
      setToken(response?.data?.data?.token);
      sessionStorage.setItem("permissions", response?.data?.data?.permissions);
      sessionStorage.setItem("username", response?.data?.data?.username);
      sessionStorage.setItem("admin", response?.data?.data?.admin);
      sessionStorage.setItem("superAdmin", response?.data?.data?.superAdmin);

      if (response?.data?.data?.departmentId) {
        sessionStorage.setItem("level_authority", "department");
      }
      if (response?.data?.data?.branchId) {
        sessionStorage.setItem("level_authority", "branch");
      }
      navigate("/dashboard");
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  // textfield
  const StyledTextField = styled(TextField)({
    "& input:-webkit-autofill": {
      backgroundColor: "#333",
      WebkitBoxShadow: "0 0 0 1000px #333 inset",
      WebkitTextFillColor: "#fff",
    },
    "& label": {
      color: "white",
      fontWeight: 600,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused": {
        backgroundColor: "#333",
      },
    },
  });
  return (
    <Box
      sx={{
        height: "100vh",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          maxWidth: 450,
          width: "100%",
          border: "1px solid lightgray",
          borderRadius: "16px",
          backdropFilter: "blur(40px)",
          boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
        }}
      >
        <MDBox
          sx={{
            // backgroundColor: "#00408077",
            margin: 1,
            borderRadius: "16px 16px 0 0",
            py: 2,
            px: 2,
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "600",
              color: "#fff",
            }}
          >
            Welcome Back
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#cce5ff",
              mt: 1,
            }}
          >
            Collection Monitoring System
          </Typography>
        </MDBox>

        <MDBox sx={{ py: 2, px: 3 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              {/* Email Field */}
              <Controller
                name="username"
                control={control}
                rules={{
                  required: "Username is required",
                  // pattern: {
                  //   value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  //   message: "Invalid email address",
                  // },
                }}
                render={({ field }) => (
                  <StyledTextField
                    {...field}
                    label="Username"
                    type="text"
                    fullWidth
                    variant="outlined"
                    error={!!errors.mail}
                    helperText={errors.mail?.message}
                    InputProps={{
                      style: { color: "#fff", backgroundColor: "#333" }, // consistent background color
                    }}
                    InputLabelProps={{
                      style: { color: "white", fontWeight: 600 }, // label color
                    }}
                    FormHelperTextProps={{
                      style: { color: "#fff" }, // error text color
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#fff", // border color
                        },
                        "&:hover fieldset": {
                          borderColor: "#fff", // border color on hover
                        },
                        "&.Mui-focused": {
                          backgroundColor: "#333", // keep background color same on focus
                        },
                      },
                    }}
                  />
                )}
              />

              {/* Password Field */}
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "Password is required",
                  minLength: {
                    value: 4,
                    message: "Password must be at least 4 characters long",
                  },
                }}
                render={({ field }) => (
                  <StyledTextField
                    {...field}
                    label="Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    InputProps={{
                      style: { color: "#fff", backgroundColor: "#333" }, // consistent background color
                    }}
                    InputLabelProps={{
                      style: { color: "white", fontWeight: 600 }, // label color
                    }}
                    FormHelperTextProps={{
                      style: { color: "#fff" }, // error text color
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#fff", // border color
                        },
                        "&:hover fieldset": {
                          borderColor: "#fff", // border color on hover
                        },
                        "&.Mui-focused": {
                          backgroundColor: "#333", // keep background color same on focus
                        },
                      },
                    }}
                  />
                )}
              />

              <MDButton
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                sx={{
                  mt: 2,
                  fontSize: "16px",
                  fontWeight: "bold",
                  py: 1.5,
                  backgroundColor: "#004080",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#003366",
                  },
                }}
              >
                {isSubmitting ? <CircularProgress size={22} /> : "Sign In"}
              </MDButton>
            </Stack>
          </form>

          {/* <Box
            sx={{
              textAlign: "center",
              mt: 2,
            }}
          >
            <Typography variant="body2" sx={{ color: "#666", fontSize: "14px" }}>
              Forgot your password?{" "}
              <Typography
                component="span"
                sx={{
                  color: "#004080",
                  fontWeight: "500",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                Reset it here.
              </Typography>
            </Typography>
          </Box> */}
        </MDBox>
      </Card>
    </Box>
  );
}

export default SignIn;
