import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Loader from "components/Loader";
import axios from "axios";
import { styled } from "@mui/material/styles";
import CreateUser from "./CreateUser"; // Component for creating users
import { toast } from "react-toastify";
import { backendUrl } from "utils/env";
import ConfirmationDialog from "components/ConfirmationDialog";
import ListPagination from "components/ListPagination";
import { token } from "utils/userToken";

// Define fixed width for all table cells
const cellWidth = 150;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#edf6f9",
  },
}));

function UserList() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const itemsPerPage = 10;

  // Fetch users
  const fetchUsers = async () => {
    try {
      const url = `${backendUrl}/user/get/AllUsers`;
      const response = await axios({
        method: "get",
        url: url,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        setUsers(response.data.data || []);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    setCurrentPage(1); // Reset to the first page when search changes
  }, [searchValue]);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredData = users.filter(
    (user) =>
      user.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
      user.username?.toLowerCase().includes(searchValue.toLowerCase())
  );
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = filteredData.length > 0 ? Math.ceil(filteredData.length / itemsPerPage) : 1;

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Dialog Handlers
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  // Create User
  const handleCreateUser = async (data) => {
    const url = `${backendUrl}/user/create`;
    try {
      const response = await axios.post(url, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success(response.data.message);
      setUsers((prev) => [...prev, response.data.data]);
      setOpenDialog(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    }
  };

  // Delete User
  const handleDeleteUser = async () => {
    if (!userToDelete) return;

    const url = `${backendUrl}/user/manage/delete/${userToDelete}`;
    try {
      const response = await axios({
        method: "delete",
        url: url,
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success(response.data.message);
      setUsers((prev) => prev.filter((user) => user.id !== userToDelete));
      setOpenDeleteDialog(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar setSearchValue={setSearchValue} loading={loading} show />
      {loading ? (
        <Loader />
      ) : (
        <MDBox sx={{ mt: 3 }}>
          <Typography variant="h5" gutterBottom textAlign={"center"}>
            User List
          </Typography>

          <Button
            variant="contained"
            className="ActionButtonsColor"
            sx={{ mb: 1, display: "block", ml: "auto" }}
            onClick={handleOpenDialog}
          >
            Create User
          </Button>

          <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
            <DialogTitle>Create New User</DialogTitle>
            <DialogContent>
              <CreateUser onSave={handleCreateUser} onCancel={handleCloseDialog} />
            </DialogContent>
          </Dialog>

          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 250px)",
              border: "1px solid lightgray",
              overflow: "auto",
              boxShadow: 0,
            }}
          >
            <Table sx={{ minWidth: 600 }}>
              <TableHead sx={{ display: "contents" }}>
                <TableRow
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    color: "white",
                  }}
                  className="NewTableHeader"
                >
                  {["Full Name", "Username", "Department", "Branch", "Actions"].map((header) => (
                    <TableCell
                      key={header}
                      sx={{ width: cellWidth }}
                      className="NewTableHeaderFontColor"
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentData.length ? (
                  currentData.map((user) => (
                    <StyledTableRow key={user.id}>
                      <TableCell className="NewTableCells">{user.name}</TableCell>
                      <TableCell className="NewTableCells">{user.username}</TableCell>
                      {/* <TableCell className="NewTableCells">{user.roleId}</TableCell> */}
                      <TableCell className="NewTableCells">
                        {user.departmentName || "N/A"}
                      </TableCell>
                      <TableCell className="NewTableCells">{user.branchName || "N/A"}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setUserToDelete(user.username);
                            setOpenDeleteDialog(true);
                          }}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No Users Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Stack direction="row" justifyContent="flex-end" mt={2}>
            <ListPagination
              count={totalPages}
              page={currentPage}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>

          <ConfirmationDialog
            open={openDeleteDialog}
            onClose={() => setOpenDeleteDialog(false)}
            onConfirm={handleDeleteUser}
            message="Are you sure you want to delete this user?"
          />
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default UserList;
