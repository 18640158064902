// @mui material components
import { forwardRef, useEffect, useState } from "react";
import React from "react";
import {
  Button,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  Grid,
  FormControl,
  CircularProgress,
} from "@mui/material";
// components
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios from "axios";
import { useLocation } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Loader from "components/Loader";
import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { token } from "utils/userToken";
// new interview modal transition
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Register_Case() {
  const [loading, setLoading] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const litesBackendUrl = process.env.REACT_APP_BACKEND_URL_LITES;
  const route = useLocation().pathname.split("/").slice(1);
  const id = route[route.length - 1];
  // new interview modal

  const [modalOpen, setModalOpen] = useState(false);
  const handleNewEvent = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };
  const validationSchema = Yup.object({
    branchId: Yup.string().required("Select Branch"),
    caseYear: Yup.string().required("Case Year is required"),
    caseNo: Yup.string().required("Case Number is required"),
    fileNo: Yup.string().required("File Number is required"),
    category: Yup.string().required("Category is required"),
    subCategory: Yup.string().required("Sub Category is required"),
    matter: Yup.string().required("Matter is required"),
    subMatter: Yup.string().required("Sub Matter is required"),
    priority: Yup.string().required("Priority is required"),
    regDate: Yup.string()
      .required("Registration Date is required")
      .test(
        "not-future-date",
        "Registration Date cannot be in the future",
        (value) => !value || moment(value).isSameOrBefore(moment(), "day")
      ),
    remarks: Yup.string().required("Remarks is required"),
  });
  const defaultValues = {
    parentDepartmentId: "",
    branchId: "",
    courtId: "COURT-e001e3a1-4546-4af6-ac0e-40067aa46044",
    caseYear: "",
    caseNo: "",
    fileNo: "",
    category: "",
    subCategory: "",
    matter: "",
    subMatter: "",
    priority: "",
    regDate: "",
    remarks: "",
  };
  //   {
  //     "parentDepartmentId": "",
  //     "branchId": "BR-d03b0952-0bff-4343-9f99-7b59ebf14115",
  //     "courtId": "COURT-e001e3a1-4546-4af6-ac0e-40067aa46044",
  //     "caseYear": "2024",
  //     "caseNo": "11",
  //     "fileNo": "asd",
  //     "category": "asd",
  //     "subCategory": "asd",
  //     "matter": "asd",
  //     "subMatter": "asd",
  //     "priority": "medium",
  //     "regDate": "2024-09-03T12:55",
  //     "remarks": "asds"
  //   }
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const linkCaseWithAccount = async (data) => {
    const url = backendUrl + "/cases/create";
    try {
      const res = await axios.post(url, data, { headers: { Authorization: `Bearer ${token}` } });
      setModalOpen(false);
      setCaseHistory((prev) => [...prev, data]);
    } catch (error) {
      console.log(error?.response?.data?.message | error.message);
    }
  };

  const onSubmit = async (data) => {
    try {
      const res = await axios.post(`${litesBackendUrl}/Cases/register`, data);
      if (res.status === 200) {
        linkCaseWithAccount({
          accountNumber: id,
          caseNo: data?.caseNo,
          category: data?.category,
          subCategory: data?.subCategory,
          matter: data?.subCategory,
          regDate: data?.regDate,
        });
        toast.success(res.data.message);
        reset(defaultValues);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Network Error");
    }
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
    // hide last border
  }));

  const [caseHistory, setCaseHistory] = useState([]);
  const getCaseHistory = async () => {
    const url = backendUrl + `/cases/${id}`;
    try {
      const res = await axios({
        method: "get",
        url: url,
        headers: { Authorization: `Bearer ${token}` },
      });
      setCaseHistory(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const [caseDetails, setCaseDetails] = useState({});
  const [openDetails, setOpenDetails] = useState(false);
  const [viewDetailsLoading, setViewDetailsLoading] = useState("");
  const handleView = async (id) => {
    setViewDetailsLoading(id);
    const url = litesBackendUrl + `/Cases/manage/getByCaseNo?caseNo=${id}`;
    try {
      const res = await axios({
        method: "get",
        url: url,
        headers: { Authorization: `Bearer ${token}` },
      });
      setOpenDetails(true);
      setCaseDetails(res.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setViewDetailsLoading("");
    }
  };
  const handleCloseViewDetails = () => {
    setOpenDetails(false);
  };
  useEffect(() => {
    getCaseHistory();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox sx={{ mt: 3 }}>
          <Typography sx={{ marginLeft: "3px", textAlign: "center" }} variant="h4">
            Case History
          </Typography>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 220px)",
              boxShadow: 0,
              border: "1px solid lightgray",
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ width: "100%", display: "contents" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    backgroundColor: "#49a3f1",
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                  }}
                >
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Sr No
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Case No
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    category
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    subCategory
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Register Date
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {caseHistory.length ? (
                  caseHistory.map((item, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <TableCell className="NewTableCells" align="left">
                          {index}
                        </TableCell>
                        <TableCell className="NewTableCells" align="left">
                          {item.caseNo ? item.caseNo : "---"}
                        </TableCell>
                        <TableCell className="NewTableCells" align="left">
                          {item.category ? item.category : "---"}
                        </TableCell>
                        <TableCell className="NewTableCells" align="left">
                          {item.subCategory ? item.subCategory : "---"}
                        </TableCell>
                        <TableCell className="NewTableCells" align="left">
                          {item.regDate ? moment(item.regDate).format("DD-MM-YYYY hh:mm") : "---"}
                        </TableCell>
                        <TableCell className="NewTableCells" align="left">
                          <Button
                            disabled={viewDetailsLoading}
                            onClick={() => handleView(item.caseNo)}
                          >
                            {viewDetailsLoading == item.caseNo ? (
                              <CircularProgress size={20} />
                            ) : (
                              "View Details"
                            )}
                          </Button>
                        </TableCell>
                      </StyledTableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack alignItems="flex-end" my={1}>
            <Button
              className="ActionButtonsColor"
              variant="contained"
              onClick={handleNewEvent}
              sx={{ color: "white !important", background: "#49a3ffff" }}
            >
              Register New
            </Button>
          </Stack>
        </MDBox>
      )}
      <Dialog
        open={Boolean(modalOpen)}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Register New Case"}</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            minWidth: "450px",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} p={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  select
                  id="branchId"
                  name="branchId"
                  SelectProps={{
                    native: true, // Use native select element
                  }}
                  InputLabelProps={{
                    shrink: true, // This forces the label to shrink
                  }}
                  label="Select Branch"
                  {...register("branchId")}
                  error={!!errors.branchId}
                  helperText={errors.branchId?.message}
                >
                  <option value="">Select Branch</option>
                  <option value="BR-d03b0952-0bff-4343-9f99-7b59ebf14115">Branch 01</option>
                  {/* Add more event types as needed */}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="caseYear"
                  name="caseYear"
                  label="Case Year"
                  {...register("caseYear")}
                  error={!!errors.caseYear}
                  helperText={errors.caseYear?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="caseNo"
                  name="caseNo"
                  label="Case Number"
                  {...register("caseNo")}
                  error={!!errors.caseNo}
                  helperText={errors.caseNo?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="fileNo"
                  name="fileNo"
                  label="File Number"
                  {...register("fileNo")}
                  error={!!errors.fileNo}
                  helperText={errors.fileNo?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="category"
                  name="category"
                  label="Category"
                  {...register("category")}
                  error={!!errors.category}
                  helperText={errors.category?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="subCategory"
                  name="subCategory"
                  label="Sub Category"
                  {...register("subCategory")}
                  error={!!errors.subCategory}
                  helperText={errors.subCategory?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="matter"
                  name="matter"
                  label="Matter"
                  {...register("matter")}
                  error={!!errors.matter}
                  helperText={errors.matter?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="subMatter"
                  name="subMatter"
                  label="Sub Matter"
                  {...register("subMatter")}
                  error={!!errors.subMatter}
                  helperText={errors.subMatter?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  fullWidth
                  id="priority"
                  SelectProps={{
                    native: true, // Use native select element
                  }}
                  InputLabelProps={{
                    shrink: true, // This forces the label to shrink
                  }}
                  name="priority"
                  label="Priority"
                  {...register("priority")}
                  error={!!errors.priority}
                  helperText={errors.priority?.message}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="high">High</option>
                  <option value="medium">Medium</option>
                  <option value="low">Low</option>
                  {/* Add more event types as needed */}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    type="datetime-local"
                    id="regDate"
                    name="regDate"
                    label="Date"
                    {...register("regDate")}
                    error={!!errors.regDate}
                    helperText={errors.regDate?.message}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  multiline
                  id="remarks"
                  name="remarks"
                  label="Remarks"
                  {...register("remarks")}
                  error={!!errors.remarks}
                  helperText={errors.remarks?.message}
                />
              </Grid>
            </Grid>

            <Button
              className="ActionButtonsColor"
              type="submit"
              fullWidth
              sx={{ mt: 1 }}
              variant="contained"
              disabled={isSubmitting}
            >
              {isSubmitting ? <CircularProgress size={22} /> : "Register Case"}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog open={openDetails} onClose={handleCloseViewDetails} maxWidth="sm" fullWidth>
        <DialogTitle>Case Details</DialogTitle>
        <DialogContent>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ color: "black !important" }}>
                    <strong>Case No:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">{caseDetails.caseNo || "N/A"}</Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ color: "black !important" }}>
                    <strong>Branch Name:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">{caseDetails.branchName || "N/A"}</Typography>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ color: "black !important" }}>
                    <strong>Court Name:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">{caseDetails.courtName || "N/A"}</Typography>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ color: "black !important" }}>
                    <strong>Case Year:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">{caseDetails.caseYear || "N/A"}</Typography>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ color: "black !important" }}>
                    <strong>Case Lawyer:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">{caseDetails.lawyername || "N/A"}</Typography>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ color: "black !important" }}>
                    <strong>File No:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">{caseDetails.fileNo || "N/A"}</Typography>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ color: "black !important" }}>
                    <strong>Category:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">{caseDetails.category || "N/A"}</Typography>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ color: "black !important" }}>
                    <strong>Sub-Category:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">{caseDetails.subCategory || "N/A"}</Typography>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ color: "black !important" }}>
                    <strong>Matter:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">{caseDetails.matter || "N/A"}</Typography>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ color: "black !important" }}>
                    <strong>Sub-Matter:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">{caseDetails.subMatter || "N/A"}</Typography>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ color: "black !important" }}>
                    <strong>Priority:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">{caseDetails.priority || "N/A"}</Typography>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ color: "black !important" }}>
                    <strong>Registration Date:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">
                    {new Date(caseDetails.regDate).toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ color: "black !important" }}>
                    <strong>Remarks:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">{caseDetails.remarks || "N/A"}</Typography>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ color: "black !important" }}>
                    <strong>Decision:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">{caseDetails.decision || "Pending"}</Typography>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ color: "black !important" }}>
                    <strong>Created Time:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">
                    {new Date(caseDetails.createdTime).toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ color: "black !important" }}>
                    <strong>Updated Time:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">
                    {new Date(caseDetails.updatedTime).toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ color: "black !important" }}>
                    <strong>Parent Department Name:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">{caseDetails.parentDepartmentName || "N/A"}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseViewDetails} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Register_Case;
