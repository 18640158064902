import React from "react";
import { Pagination } from "@mui/material";
export default function ListPagination({ count, page, variant, shape, onChange, color }) {
  return (
    <Pagination
      count={count}
      page={page}
      variant={variant}
      shape={shape}
      onChange={onChange}
      color={color}
    />
  );
}
