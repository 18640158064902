import React, { useEffect, useState } from "react";
import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Paper,
  CircularProgress,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios from "axios";
import Loader from "components/Loader";
import MDBox from "components/MDBox";
import { useNavigate } from "react-router-dom";
import { IconUpload } from "@tabler/icons-react";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import { token } from "utils/userToken";
import ListPagination from "components/ListPagination";

function Alerts() {
  const navigate = useNavigate();
  const [resolveDialogOpen, setResolveDialogOpen] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const itemsPerPage = 20;

  const url = `${backendUrl}/onlineAlert/FRM/getAll`;
  const getData = async () => {
    try {
      const res = await axios({
        method: "get",
        url: url,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setData(res?.data?.data?.nonRejectedAlerts);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (currentPage !== 1) setCurrentPage(1);
  }, [searchValue]);

  const renderSortArrow = (column) => {
    if (sortColumn === column) {
      return sortDirection === "asc" ? <span>&#9650;</span> : <span>&#9660;</span>;
    }
    return null;
  };

  const handleSort = (column) => {
    setCurrentPage(1);
    if (column === sortColumn) {
      setSortDirection((prevSortDirection) => (prevSortDirection === "asc" ? "desc" : "asc"));
    } else {
      setSortColumn(column);
      setSortDirection("desc");
    }
  };

  const sortedData = [...data].sort((a, b) => {
    let aValue = a[sortColumn];
    let bValue = b[sortColumn];

    if (sortColumn === "acctNo") {
      aValue = parseInt(aValue, 10);
      bValue = parseInt(bValue, 10);
    }

    if (sortDirection === "asc") {
      if (typeof aValue === "number" && typeof bValue === "number") {
        return aValue - bValue;
      }
      return aValue?.localeCompare(bValue);
    } else {
      if (typeof aValue === "number" && typeof bValue === "number") {
        return bValue - aValue;
      }
      return bValue?.localeCompare(aValue);
    }
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredData = sortedData.filter(
    (item) =>
      item?.acctNo?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      item?.description?.toLowerCase().includes(searchValue?.toLowerCase())
  );
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = filteredData.length > 0 ? Math.ceil(filteredData.length / itemsPerPage) : 1;

  const nextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  // resolve
  const initialResolveAlert = {
    description: "",
    segement: "",
    acctNumber: "",
    documentName: [],
    guid: "",
  };
  const [resolveAlert, setResolveAlert] = useState(initialResolveAlert);
  const [open, setOpen] = useState(false);
  const [resolveLoading, setResolveLoading] = useState(false);
  const handleResolve = async (e) => {
    e.preventDefault();
    if (resolveAlert.documentName.length === 0) {
      toast.info("Please Upload Documents");
      return;
    }
    setResolveLoading(true);
    const url = backendUrl + "/FrmAlertResolution/create";
    try {
      const res = await axios.post(url, resolveAlert, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setOpen(false);
        toast.success(res?.data?.message);
        setData((prev) => prev?.filter((item) => item.guid !== resolveAlert.guid));
        setResolveAlert(initialResolveAlert);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    } finally {
      setResolveLoading(false);
    }
  };
  const [uploadLoading, setUploadLoading] = useState(false);
  const handleFileChange = async (e) => {
    setUploadLoading(true);
    const url =
      backendUrl +
      `/FrmAlertResolution/${resolveAlert.description}/${resolveAlert.acctNumber}/upload`;
    const files = e.target.files;
    if (files.length === 0) return;

    const formData = new FormData();
    const fileNames = [];

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
      fileNames.push(files[i].name);
    }

    try {
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setResolveAlert((prev) => ({
          ...prev,
          documentName: [...prev.documentName, ...fileNames],
        }));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    } finally {
      setUploadLoading(false);
    }
  };
  console.log(resolveAlert);
  const handleClose = () => {
    setOpen(false);
    setResolveAlert(initialResolveAlert);
  };
  const StyledTableRow = styled(TableRow)(({ theme, comment }) => ({
    backgroundColor: comment ? "#ffcccc !important" : "",
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar setSearchValue={setSearchValue} loading={loading} show={true} />
      {loading ? (
        <Loader />
      ) : (
        <MDBox sx={{ mt: 3 }}>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 190px)",
              boxShadow: 0,
              border: "1px solid lightgray",
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ display: "contents", userSelect: "none" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    backgroundColor: "#49a3f1",
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    cursor: "pointer",
                  }}
                >
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Account No {renderSortArrow("acctNo")}
                  </TableCell>
                  {/* <TableCell className="NewTableHeaderFontColor" align="left">
                    Branch
                  </TableCell> */}
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => handleSort("date")}
                  >
                    Date {renderSortArrow("date")}
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => handleSort("description")}
                  >
                    Description {renderSortArrow("description")}
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => handleSort("rejectComments")}
                  >
                    Reject Remarks {renderSortArrow("rejectComments")}
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => handleSort("segement")}
                  >
                    Segment {renderSortArrow("segement")}
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {currentData.length ? (
                  currentData.map((row, index) => (
                    <StyledTableRow key={index + 1} comment={!!row.rejectComments}>
                      <TableCell className="NewTableCells" align="left">
                        {row.acctNo}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.date}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.description}
                      </TableCell>
                      <TableCell
                        sx={{ backgroundColor: row.rejectCommnets ? "red" : "" }}
                        className="NewTableCells"
                        align="left"
                      >
                        {row.rejectComments}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.segement}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        <Button
                          onClick={() => {
                            setOpen(true);
                            setResolveAlert((prev) => ({
                              ...prev,
                              description: row.description,
                              acctNumber: row.acctNo,
                              segement: row.segement,
                              guid: row.guid,
                              branchCode: row.branchCode,
                            }));
                          }}
                        >
                          Resolve
                        </Button>
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>No Data</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack direction="row" spacing={2} justifyContent="flex-end" m={1}>
            <ListPagination
              count={totalPages}
              page={currentPage}
              variant="outlined"
              shape="rounded"
              onChange={(event, value) => setCurrentPage(value)}
              color="primary"
            />
          </Stack>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{resolveAlert.description}</DialogTitle>
            <DialogContent>
              <form onSubmit={handleResolve}>
                {/* <Typography variant="h6">- {resolveAlert.description}</Typography> */}
                <TextField
                  required
                  multiline
                  rows={3}
                  fullWidth
                  value={resolveAlert.resoluationDesc}
                  onChange={(e) =>
                    setResolveAlert((prev) => ({ ...prev, resoluationDesc: e.target.value }))
                  }
                  disabled={uploadLoading || resolveLoading}
                  placeholder="Please Elaborate"
                ></TextField>
                <Button
                  variant="outlined"
                  component="label"
                  startIcon={uploadLoading ? null : <IconUpload size={20} />}
                  sx={{ color: "blue !important", mt: 1 }}
                  fullWidth
                >
                  {uploadLoading ? <CircularProgress size={24} /> : "Upload Documents"}
                  <input
                    disabled={resolveLoading || uploadLoading}
                    type="file"
                    hidden
                    multiple
                    onChange={handleFileChange}
                  />
                </Button>
                <TableContainer
                  component={Paper}
                  sx={{ boxShadow: 0, border: "1px solid lightgray", mt: 1, maxHeight: "203px" }}
                >
                  <Table>
                    {/* <TableHead> */}
                    <TableRow>
                      <TableCell sx={{ fontWeight: 700 }}>Number</TableCell>
                      <TableCell sx={{ fontWeight: 700 }}>Document Name</TableCell>
                    </TableRow>
                    {/* </TableHead> */}
                    <TableBody>
                      {resolveAlert.documentName && resolveAlert.documentName.length > 0 ? (
                        resolveAlert.documentName.map((document, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{document}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            No documents available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Button
                  variant="contained"
                  sx={{ color: "white !important", mt: 1 }}
                  // onClick={handleResolve}
                  type="submit"
                  fullWidth
                  disabled={resolveLoading || uploadLoading}
                >
                  {resolveLoading ? <CircularProgress color="info" size={24} /> : "Submit"}
                </Button>
              </form>
            </DialogContent>
          </Dialog>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default Alerts;
