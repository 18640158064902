import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
} from "@mui/material";

function ConfirmationDialog({ open, onClose, onConfirm, message }) {
  const [loading, setLoading] = useState(false);
  const yes = async () => {
    setLoading(true);
    await onConfirm();
    setLoading(false);
  };
  return (
    <Dialog open={open} onClose={loading ? null : onClose}>
      <DialogTitle>Confirm Action</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>
          No
        </Button>
        <Button disabled={loading} onClick={yes} variant="contained" className="ActionButtonsColor">
          {loading ? <CircularProgress size={20} /> : "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
