import { useEffect } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import { IconButton, Typography } from "@mui/material";
import { IconSquareRoundedX } from "@tabler/icons-react";
import CustomLanguageSelector from "Translation";

import {
  IconAlertOctagon,
  IconAlertTriangle,
  IconBuilding,
  IconDashboard,
  IconHierarchy,
  IconNews,
  IconNotebook,
  IconNotes,
  IconPasswordUser,
  IconUsers,
} from "@tabler/icons-react";

function Sidenav({ color, brandName, ...rest }) {
  const isAdmin = sessionStorage.getItem("admin") == "true";
  const isSuperAdmin = sessionStorage.getItem("superAdmin") == "true";
  const routes = [
    {
      type: "title",
      name: "Dashboard",
      key: "dashboard-title",
    },
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      icon: <IconDashboard />,
      route: "/dashboard",
    },

    ...(isAdmin || isSuperAdmin
      ? [
          {
            type: "title",
            name: "Masters",
            key: "masters-title",
          },
          {
            type: "collapse",
            name: "Departments",
            key: "departments",
            icon: <IconBuilding />,
            route: "/departments",
          },
          {
            type: "collapse",
            name: "Branches",
            key: "branches",
            icon: <IconHierarchy />,
            route: "/branches",
          },
          {
            type: "collapse",
            name: "Roles",
            key: "roles",
            icon: <IconPasswordUser />,
            route: "/roles",
          },
          {
            type: "collapse",
            name: "Users",
            key: "users",
            icon: <IconUsers />,
            route: "/users",
          },
        ]
      : []),
    {
      type: "title",
      name: "Summary",
      key: "Summary",
    },
    {
      type: "collapse",
      name: "Standard-Accounts",
      icon: <IconNotes />,
      key: "standard/summary",
      route: "/standard/summary",
    },
    {
      type: "collapse",
      name: "SMA-Accounts",
      icon: <IconNotebook />,
      key: "sma/summary",
      route: "/sma/summary",
    },
    {
      type: "collapse",
      name: "NPA-Accounts",
      icon: <IconNews />,
      key: "npa/summary",
      route: "/npa/summary",
    },
    {
      type: "title",
      name: "Alerts",
      key: "Alerts",
    },

    {
      type: "collapse",
      name: "Compliances - EWS",
      icon: <IconAlertTriangle />,
      key: "EWS_Alerts",
      route: "EWS_Alerts",
    },
    {
      type: "collapse",
      name: "Compliances - FRM",
      icon: <IconAlertTriangle />,
      key: "FRM_Alerts",
      route: "FRM_Alerts",
    },

    // {
    //   type: "title",
    //   name: "Compliance",
    //   key: "Compliance",
    // },
    // {
    //   type: "collapse",
    //   name: "High Level Compliances",
    //   icon: <IconListDetails />,
    //   key: "compliances",
    //   route: "/compliances",
    //
    //   childrens: [
    //     {
    //       type: "collapse",
    //       name: `Account-Compliance`,
    //       key: `compliance/:id`,
    //       route: "compliance/:id",
    //
    //     },
    //   ],
    // },
  ];
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route }) => {
    let returnValue;
    const isActive = key === collapseName || location.pathname.startsWith(`/${key}`);
    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse name={name} icon={icon} active={isActive} noCollapse={noCollapse} />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon} active={isActive} />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          sx={{ fontWeight: 700 }}
          color="white"
          display="block"
          variant="caption"
          // fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          // ml={1}
        >
          {name}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <IconButton
          onClick={closeSidenav}
          color="white"
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            p: 1.6,
            cursor: "pointer",
            "&:hover": {
              color: "red",
            },
            display: { xs: "block", xl: "none" },
          }}
        >
          <IconSquareRoundedX />
        </IconButton>
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={1}
          flexDirection="column"
        >
          <img src="/logo.png" alt="Logo" width={80} />
          <MDBox
            // width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography variant="h4" fontWeight="medium" color="white">
              {brandName}
            </MDTypography>
            <MDTypography variant="h6" fontWeight="medium" color="white">
              {sessionStorage.getItem("username")}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
      <div id="google_translate_element" style={{ marginBottom: "20px" }}></div>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  // brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  // brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
