import React, { useEffect, useState } from "react";
import {
  Button,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  CircularProgress,
  IconButton,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios from "axios";
import Loader from "components/Loader";
import MDBox from "components/MDBox";
import { useNavigate } from "react-router-dom";
import {
  IconCircleCheck,
  IconDownload,
  IconEye,
  IconSquareRoundedX,
  IconUpload,
} from "@tabler/icons-react";
import { toast } from "react-toastify";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { token } from "utils/userToken";
import ListPagination from "components/ListPagination";

function Alerts_Authority() {
  const navigate = useNavigate();
  const [selectedBranch, setSelectedBranch] = useState(null);

  const [resolveDialogOpen, setResolveDialogOpen] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const itemsPerPage = 20;

  const url = `${backendUrl}/FrmAlertResolution/all?id=${selectedBranch}`;
  const getData = async () => {
    try {
      const res = await axios({
        method: "get",
        url: url,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setData(res?.data?.data);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  // branches
  const [branchList, setBranchList] = useState([]);
  const getBranches = async () => {
    const url = backendUrl + "/branches/get/dropdown";
    try {
      const response = await axios({
        method: "get",
        url,
        headers: { Authorization: `Bearer ${token}` },
      });
      setBranchList(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    getBranches();
  }, []);

  useEffect(() => {
    if (currentPage !== 1) setCurrentPage(1);
  }, [searchValue]);

  useEffect(() => {
    getData();
  }, [selectedBranch]);

  const renderSortArrow = (column) => {
    if (sortColumn === column) {
      return sortDirection === "asc" ? <span>&#9650;</span> : <span>&#9660;</span>;
    }
    return null;
  };

  const handleSort = (column) => {
    setCurrentPage(1);
    if (column === sortColumn) {
      setSortDirection((prevSortDirection) => (prevSortDirection === "asc" ? "desc" : "asc"));
    } else {
      setSortColumn(column);
      setSortDirection("desc");
    }
  };

  const sortedData = [...data].sort((a, b) => {
    let aValue = a[sortColumn];
    let bValue = b[sortColumn];

    if (sortColumn === "acctNo") {
      aValue = parseInt(aValue, 10);
      bValue = parseInt(bValue, 10);
    }

    if (sortDirection === "asc") {
      if (typeof aValue === "number" && typeof bValue === "number") {
        return aValue - bValue;
      }
      return aValue?.localeCompare(bValue);
    } else {
      if (typeof aValue === "number" && typeof bValue === "number") {
        return bValue - aValue;
      }
      return bValue?.localeCompare(aValue);
    }
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredData = sortedData.filter(
    (item) =>
      item?.acctNo?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      item?.description?.toLowerCase().includes(searchValue?.toLowerCase())
  );
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = filteredData.length > 0 ? Math.ceil(filteredData.length / itemsPerPage) : 1;

  const nextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  // actions
  const Accept = async (id) => {
    try {
      const url = backendUrl + `/FrmAlertResolution/accept?resolutionId=${id}`;
      const res = await axios.post(url, null, { headers: { Authorization: `Bearer ${token}` } });
      if (res.status === 200) {
        toast.success(res?.data?.message);
        setData((prev) =>
          prev.map((item) => {
            return item.resoluationId === id ? { ...item, status: "Accepted" } : item;
          })
        );
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    }
  };

  // reject
  const [rejectComments, setRejectComments] = useState("");
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [rejectId, setRejectId] = useState("");
  const Reject = async () => {
    try {
      const url =
        backendUrl +
        `/FrmAlertResolution/reject?resolutionId=${rejectId}&&rejectComments=${rejectComments}`;
      const res = await axios.post(url, null, { headers: { Authorization: `Bearer ${token}` } });
      if (res.status === 200) {
        toast.success(res?.data?.message);
        const updatedDada = data?.filter((item) => item.resoluationId !== rejectId);
        setData(updatedDada);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    }
  };
  const Download = async (id) => {
    const url = `${backendUrl}/FrmAlertResolution/${id}/download`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;

      // Set the download attribute to force download
      link.setAttribute("download", "");

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up: remove the link from the DOM
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error.message);
      // Handle error as needed
    }
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
    // hide last border
  }));

  const [rejectionHistory, setRejectionHistory] = useState([]);
  const getRejectionHistory = async (accNo, desc) => {
    setLoading(true);
    const url =
      backendUrl + `/onlineAlert/getFrmResolutionHistory?acctNo=${accNo}&description=${desc}`;
    try {
      const response = await axios.get(url);
      setRejectionHistory(response?.data?.data || []);
      if (!response?.data?.data?.length) {
        toast.info("No Rejection History");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar setSearchValue={setSearchValue} loading={loading} show={true} />
      {loading ? (
        <Loader />
      ) : (
        <MDBox sx={{ mt: 3 }}>
          <FormControl sx={{ display: "block", ml: "auto", width: "200px", mb: 1 }}>
            <InputLabel id="branch-select-label">Select Branch</InputLabel>
            <Select
              labelId="branch-select-label"
              disabled={!branchList.length}
              value={selectedBranch} // State to hold the selected branch ID
              onChange={(e) => setSelectedBranch(e.target.value)} // Update state on change
              label="Select Branch"
              fullWidth
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {branchList.map((branch) => (
                <MenuItem key={branch.id} value={branch.id}>
                  {branch.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 190px)",
              boxShadow: 0,
              border: "1px solid lightgray",
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ display: "contents", userSelect: "none" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    backgroundColor: "#49a3f1",
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    cursor: "pointer",
                  }}
                >
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Account No {renderSortArrow("acctNo")}
                  </TableCell>
                  {/* <TableCell className="NewTableHeaderFontColor" align="left">
                    Branch
                  </TableCell> */}
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => handleSort("date")}
                  >
                    Date {renderSortArrow("date")}
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => handleSort("description")}
                  >
                    Description {renderSortArrow("description")}
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => handleSort("resoluation")}
                  >
                    Resoluation {renderSortArrow("resoluation")}
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => handleSort("segement")}
                  >
                    Segment {renderSortArrow("segement")}
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => handleSort("segement")}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => setResolveDialogOpen(true)}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {currentData.length ? (
                  currentData.map((row, index) => (
                    <StyledTableRow key={index + 1}>
                      <TableCell className="NewTableCells" align="left">
                        {row.acctNumber || "---"}
                      </TableCell>
                      {/* <TableCell className="NewTableCells" align="left">
                        Ahmedabad {index + 1}
                      </TableCell> */}
                      <TableCell className="NewTableCells" align="left">
                        {moment(row.createdAt).format("DD-MM-YYYY") || "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.description || "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.resoluationDesc || "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.segement || "---"}
                      </TableCell>
                      <TableCell
                        className="NewTableCells"
                        align="left"
                        sx={{ color: row.status === "Accepted" ? "green" : "orange" }}
                      >
                        {row.status || "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.status === "Pending" ? (
                          <>
                            <Button
                              size="small"
                              // color="success"
                              startIcon={<IconCircleCheck />}
                              sx={{ color: "green" }}
                              onClick={() => Accept(row.resoluationId)}
                            >
                              Accept
                            </Button>
                            <Button
                              size="small"
                              // color="error"
                              sx={{ color: "red" }}
                              startIcon={<IconSquareRoundedX />}
                              onClick={() => {
                                setRejectId(row.resoluationId);
                                setOpenRejectDialog(true);
                              }}
                            >
                              Reject
                            </Button>
                            <Button
                              size="small"
                              startIcon={<IconDownload />}
                              onClick={() => Download(row.resoluationId)}
                            >
                              Documents
                            </Button>
                            <Button
                              size="small"
                              startIcon={<IconEye />}
                              onClick={() => getRejectionHistory(row.acctNumber, row.description)}
                            >
                              View Rejection History
                            </Button>
                          </>
                        ) : (
                          <>{<Typography variant="subtitle2">No Action Pending</Typography>}</>
                        )}
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>No Data</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack direction="row" spacing={2} justifyContent="flex-end" m={1}>
            <ListPagination
              count={totalPages}
              page={currentPage}
              variant="outlined"
              shape="rounded"
              onChange={(event, value) => setCurrentPage(value)}
              color="primary"
            />
          </Stack>
        </MDBox>
      )}
      <Dialog
        open={openRejectDialog}
        onClose={() => (rejectLoading ? false : setOpenRejectDialog(false))}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ fontSize: 18 }}>Reject Resolution</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: 1 }}
            fullWidth
            label="Reject Remarks"
            onChange={(e) => setRejectComments(e.target.value)}
            value={rejectComments}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={rejectLoading} onClick={() => setOpenRejectDialog(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className="ActionButtonsColor"
            disabled={rejectLoading}
            onClick={() => {
              if (rejectId) Reject();
            }}
          >
            {rejectLoading ? <CircularProgress size={22} /> : "Reject"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="md"
        fullWidth
        open={rejectionHistory?.length}
        onClose={() => setRejectionHistory([])}
      >
        <IconButton
          sx={{ position: "absolute", top: "5px", right: "5px" }}
          onClick={() => setRejectionHistory([])}
        >
          <IconSquareRoundedX />
        </IconButton>
        <DialogTitle>Rejection History</DialogTitle>
        <DialogContent>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 190px)",
              boxShadow: 0,
              border: "1px solid lightgray",
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ display: "contents", userSelect: "none" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    backgroundColor: "#49a3f1",
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    cursor: "pointer",
                  }}
                >
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Account No {renderSortArrow("acctNo")}
                  </TableCell>

                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => handleSort("date")}
                  >
                    Date {renderSortArrow("date")}
                  </TableCell>

                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => handleSort("resoluation")}
                  >
                    Resoluation {renderSortArrow("resoluation")}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {rejectionHistory?.length ? (
                  rejectionHistory?.map((row, index) => (
                    <StyledTableRow key={index + 1}>
                      <TableCell className="NewTableCells" align="left">
                        {row.acctNumber || "---"}
                      </TableCell>

                      <TableCell className="NewTableCells" align="left">
                        {moment(row.createdAt).format("DD-MM-YYYY") || "---"}
                      </TableCell>

                      <TableCell className="NewTableCells" align="left">
                        {row.resoluationDesc || "---"}
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>No Data</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
}

export default Alerts_Authority;
