// @mui material components
import { forwardRef, useEffect, useRef, useState } from "react";
import React from "react";
import {
  Button,
  Stack,
  Typography,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// components
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Loader from "components/Loader";
import { token } from "utils/userToken";

// data
const styles = {
  paper: {
    padding: "20px",
    maxWidth: "600px",
    margin: "20px auto",
  },
  header: {
    marginBottom: "10px",
  },
  address: {
    marginBottom: "10px",
  },
  letterDetails: {
    marginBottom: "10px",
  },
  content: {
    marginBottom: "10px",
  },
  closing: {
    marginTop: "20px",
  },
};

// new interview modal transition
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Notice() {
  const [loading, setLoading] = useState(true);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const route = useLocation().pathname.split("/").slice(1);
  const id = route[route.length - 1];

  const [formData, setFormData] = useState({});
  let currentDate = new Date().toLocaleString();

  // new interview modal
  const componentRef = useRef();
  const generateReport = useReactToPrint({
    content: () => componentRef.current,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const handleNewNotice = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = async () => {
    const url = backendUrl + "/Notice/create";
    try {
      const res = await axios.post(
        url,
        {
          accountNumber: id,
          issuedTo: formData?.name,
          noticeType: formData?.noticeType,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (res.status === 200) {
        toast.success(res?.data?.message);
        getNotice();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      handleClose();
    }
  };
  const getNotice = async () => {
    const url = backendUrl + `/Notice/${id}`;
    try {
      const res = await axios({ url: url, headers: { Authorization: `Bearer ${token}` } });
      if (res.status === 200) {
        setFormData(res?.data?.data);
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getNotice();
  }, []);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
    // hide last border
  }));
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox sx={{ mt: 3 }}>
          <Typography sx={{ marginLeft: "3px", textAlign: "center" }} variant="h4">
            Notice History
          </Typography>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 220px)",
              boxShadow: 0,
              border: "1px solid lightgray",
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ width: "100%", display: "contents" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    backgroundColor: "#49a3f1",
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                  }}
                >
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Account No
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Notice Type
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Issued On
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Issued To
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Report
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.history && formData.history.length ? (
                  formData.history.map((row) => (
                    <StyledTableRow key={row?.accountNumber}>
                      <TableCell className="NewTableCells" align="left">
                        {row?.accountNumber ? row?.accountNumber : "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row?.noticeType ? row?.noticeType : "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row?.issuedDate
                          ? moment(row?.issuedDate).format("DD-MM-YYYY hh:mm")
                          : "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row?.issuedTo ? row?.issuedTo : "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.report ? (
                          <a href={`${row.report}${row.notice_id}`} download>
                            <Button>Download</Button>
                          </a>
                        ) : (
                          "---"
                        )}
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack alignItems="flex-end" my={1}>
            <Button
              className="ActionButtonsColor"
              variant="contained"
              onClick={handleNewNotice}
              sx={{ color: "white !important", background: "#49a3ffff" }}
            >
              Record New
            </Button>
          </Stack>
        </MDBox>
      )}
      <Dialog
        open={Boolean(modalOpen)}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"RECORD NEW NOTICE"}</DialogTitle>
        <DialogContent>
          {/* <div style={{ padding: "10px" }} ref={componentRef}>
            <Typography variant="body1" paragraph>
              {mailContent?.split("\n").map((line, index) => (
                <Typography key={index} variant="body1" paragraph>
                  {line}
                </Typography>
              ))}
            </Typography>
          </div> */}
          <div style={{ padding: "10px" }} ref={componentRef}>
            <div style={styles.header}>
              <Typography variant="h5">{`${currentDate}`}</Typography>
            </div>
            <div style={styles.address}>
              <Typography variant="h5">To,</Typography>
              <Typography variant="h5">{`${formData?.name},`}</Typography>
              <Typography variant="h5">
                {formData?.add1},{formData?.add2},{formData?.add3},{formData?.add4},
              </Typography>
            </div>
            <br />
            <div style={styles.letterDetails}>
              <Typography variant="h5">{`Letter NO: ${formData.noticeType},`}</Typography>
              <Typography variant="h5">{`YOURS/OTHERS LOAN/OD/CC ACCOUNT NO: ${formData?.acctNo}`}</Typography>
            </div>
            <br />
            <div style={styles.content}>
              <Typography variant="h6">{`1. The above mentioned loan account number is irregular by Rs ${formData?.irregAmt}. You are requested to repay the said amount at the earliest to prevent further slippage.`}</Typography>
              <br />
              <Typography variant="h6">{`2. As you are aware, irregularity in the amount attracts a penal interest @ 2% of the overdue amount.`}</Typography>
              <br />
              <Typography variant="h6">{`3. Please ignore the message if you have already deposited in the account.`}</Typography>
            </div>
            <div style={styles.closing}>
              <Typography variant="h5">Yours faithfully,</Typography>
              <Typography variant="h5">Branch Manager</Typography>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleSubmit}>Submit</Button>
          <Button onClick={generateReport}>Print Report</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Notice;
