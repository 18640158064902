import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Loader from "components/Loader";
import axios from "axios";
import { styled } from "@mui/material/styles";
import CreateRole from "./CreateRole";
import { toast } from "react-toastify";
import { backendUrl } from "utils/env";
import ConfirmationDialog from "components/ConfirmationDialog";
import ListPagination from "components/ListPagination";
import { token } from "utils/userToken";

const cellWidth = 150;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#edf6f9",
  },
}));

function RoleList() {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState(null);
  const itemsPerPage = 10;

  // Fetch roles
  const fetchRoles = async () => {
    try {
      const url = `${backendUrl}/roles/getAllRoles`;
      const response = await axios({
        method: "get",
        url: url,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        setRoles(response.data.data || []);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchValue]);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredData = roles.filter(
    (role) =>
      role.roleName?.toLowerCase().includes(searchValue.toLowerCase()) ||
      role.createdBy?.toLowerCase().includes(searchValue.toLowerCase())
  );

  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = filteredData.length > 0 ? Math.ceil(filteredData.length / itemsPerPage) : 1;

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleCreateRole = async (data) => {
    const url = `${backendUrl}/roles/create`;
    try {
      const response = await axios.post(url, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success(response.data.message);
      setRoles((prev) => [...prev, response.data.data]);
      setOpenDialog(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    }
  };

  const handleDeleteRole = async () => {
    if (!roleToDelete) return;

    const url = `${backendUrl}/roles/delete/${roleToDelete}`;
    try {
      const response = await axios({
        method: "delete",
        url: url,
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success(response.data.message);
      setRoles((prev) => prev.filter((role) => role.id !== roleToDelete));
      setOpenDeleteDialog(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar setSearchValue={setSearchValue} loading={loading} show />
      {loading ? (
        <Loader />
      ) : (
        <MDBox sx={{ mt: 2 }}>
          <Button
            variant="contained"
            className="ActionButtonsColor"
            sx={{ mb: 1, display: "block", ml: "auto" }}
            onClick={handleOpenDialog}
          >
            Create Role
          </Button>

          <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
            <DialogTitle>Create New Role</DialogTitle>
            <DialogContent>
              <CreateRole onSave={handleCreateRole} onCancel={handleCloseDialog} />
            </DialogContent>
          </Dialog>

          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 250px)",
              border: "1px solid lightgray",
              overflow: "auto",
              boxShadow: 0,
            }}
          >
            <Table sx={{ minWidth: 600 }}>
              <TableHead sx={{ display: "contents" }}>
                <TableRow
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    color: "white",
                  }}
                  className="NewTableHeader"
                >
                  {[
                    "Role Name",
                    "Parent Department Name",
                    "Parent Branch Name",
                    "Created By",
                    "Actions",
                  ].map((header) => (
                    <TableCell
                      key={header}
                      sx={{ width: cellWidth }}
                      className="NewTableHeaderFontColor"
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentData.length ? (
                  currentData.map((role) => (
                    <StyledTableRow key={role.id}>
                      <TableCell className="NewTableCells">{role.roleName}</TableCell>
                      <TableCell className="NewTableCells">
                        {role.departmentName || "N/A"}
                      </TableCell>
                      <TableCell className="NewTableCells">{role.branchName || "N/A"}</TableCell>
                      <TableCell className="NewTableCells">{role.createdBy || "N/A"}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setRoleToDelete(role.id);
                            setOpenDeleteDialog(true);
                          }}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No Roles Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Stack direction="row" justifyContent="flex-end" mt={2}>
            <ListPagination
              count={totalPages}
              page={currentPage}
              variant="outlined"
              shape="rounded"
              onChange={(event, value) => setCurrentPage(value)}
              color="primary"
            />
          </Stack>

          <ConfirmationDialog
            open={openDeleteDialog}
            onClose={() => setOpenDeleteDialog(false)}
            onConfirm={handleDeleteRole}
            message="Are you sure you want to delete this role?"
          />
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default RoleList;
