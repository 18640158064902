// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Dashboard components
import PieChart from "./components/PieChart";
import StatusChart from "./components/StatusChart";
import TurningNPA from "./components/TurningNPA/TurningNPA";
import NoActionTaken from "./components/NoAction/NoActionTaken";
import ActionsDueAccounts from "./components/ActionDue/ActionsDueAccounts";
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "components/Loader";
import { useNavigate } from "react-router-dom";
import OfflineAlertsPieChart from "./components/OfflineAlertPieChart";
import { token } from "utils/userToken";
import { backendUrl } from "utils/env";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [data, setData] = useState();
  const url =
    backendUrl + (selectedBranch ? `/dashboard/data?id=${selectedBranch}` : "/dashboard/data");

  const getDashboardData = async () => {
    try {
      const res = await axios({
        method: "get",
        url: url,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setData(res.data.data);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const [branchList, setBranchList] = useState([]);
  const getBranches = async () => {
    const url = backendUrl + "/branches/get/dropdown";
    try {
      const response = await axios({
        method: "get",
        url,
        headers: { Authorization: `Bearer ${token}` },
      });
      setBranchList(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, [selectedBranch]);

  useEffect(() => {
    getBranches();
  }, []);

  const navigate = useNavigate();
  const handleView = (number, category) => {
    if (!category && category != 0) {
      return;
    }
    let visitUrl = null;
    if (category == 0) {
      visitUrl = `/standard/summary/dashboard/${number}`;
    } else if (category == 1 || category == 2 || category == 3) {
      visitUrl = `/sma/summary/dashboard/${number}`;
    } else {
      visitUrl = `/npa/summary/dashboard/${number}`;
    }
    navigate(visitUrl);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox py={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl sx={{ display: "block", ml: "auto", width: "200px" }}>
                  <InputLabel id="branch-select-label">Select Branch</InputLabel>
                  <Select
                    labelId="branch-select-label"
                    disabled={!branchList.length}
                    value={selectedBranch} // State to hold the selected branch ID
                    onChange={(e) => setSelectedBranch(e.target.value)} // Update state on change
                    label="Select Branch"
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {branchList.map((branch) => (
                      <MenuItem key={branch.id} value={branch.id}>
                        {branch.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12}>
                <AlertsCount />
              </Grid> */}
              <Grid item xs={12} lg={6}>
                <PieChart
                  data={data?.pieChart}
                  handleView={handleView}
                  selectedBranch={selectedBranch}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StatusChart
                  actionDueAccounts={data?.actionDueTable?.length}
                  noActionTakenAccounts={data?.noActionTable?.length}
                  npaTurningAccounts={data?.npaTurningTable?.length}
                />
              </Grid>
              <Grid item xs={12}>
                <OfflineAlertsPieChart
                  selectedBranch={selectedBranch}
                  data={data?.offlineAlertsCount || []}
                  handleView={handleView}
                />
              </Grid>

              {/* <Grid item xs={12}>
                <BarChart />
              </Grid> */}
              <Grid item xs={12}>
                <NoActionTaken
                  data={data?.noActionTable?.length ? data?.noActionTable : []}
                  handleView={handleView}
                />
              </Grid>
              <Grid item xs={12}>
                <ActionsDueAccounts
                  data={data?.actionDueTable?.length ? data?.actionDueTable : []}
                  handleView={handleView}
                />
              </Grid>
              <Grid item xs={12}>
                <TurningNPA
                  data={data?.npaTurningTable?.length ? data.npaTurningTable : []}
                  handleView={handleView}
                />
              </Grid>
              {/* <Grid item xs={12} md={12} lg={12}>
              <Projects />
            </Grid> */}
            </Grid>
          </MDBox>
        </MDBox>
      )}

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Dashboard;
