import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Autocomplete,
} from "@mui/material";
import axios from "axios";
import { backendUrl } from "utils/env";
import { token } from "utils/userToken";

const CreateRole = ({ onSave, onCancel, defaultValues }) => {
  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: defaultValues || {
      roleName: "",
      roleLevel: "Department", // New field to select level
      parentDepartmentId: "",
      parentBranchId: "",
      isAdmin: false,
      createdBy: "",
      permissions: {
        read: [],
        write: [],
        update: [],
        delete: [],
      },
    },
  });

  const roleLevel = watch("roleLevel"); // Watch the role level field

  const onSubmit = (data) => {
    onSave(data); // Pass data to parent
    reset(); // Clear form
  };

  const permissionsOptions = ["inspection", "interview", "notice", "event", "compliance", "report"];

  // Get department list
  const [departmentList, setDepartmentList] = useState([]);
  const getDepartments = async () => {
    const url = backendUrl + "/departments/get/dropdown";
    try {
      const response = await axios({
        method: "get",
        url,
        headers: { Authorization: `Bearer ${token}` },
      });
      setDepartmentList(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  const [branchList, setBranchList] = useState([]);
  const getBranches = async () => {
    const url = backendUrl + "/branches/get/dropdown";
    try {
      const response = await axios({
        method: "get",
        url,
        headers: { Authorization: `Bearer ${token}` },
      });
      setBranchList(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDepartments();
    getBranches();
  }, []);

  useEffect(() => {
    // Clear parentDepartmentId or parentBranchId based on roleLevel
    if (roleLevel === "Department") {
      setValue("parentBranchId", "");
    } else if (roleLevel === "Branch") {
      setValue("parentDepartmentId", "");
    }
  }, [roleLevel, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "20px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="roleLevel"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="role-level-label">Role Level</InputLabel>
                <Select {...field} labelId="role-level-label" label="Role Level">
                  <MenuItem value="Department">Department</MenuItem>
                  <MenuItem value="Branch">Branch</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </Grid>

        {[{ name: "roleName", label: "Role Name", required: true }].map((field) => (
          <Grid item xs={12} sm={6} key={field.name}>
            <Controller
              name={field.name}
              control={control}
              render={({ field: inputField }) => (
                <TextField
                  {...inputField}
                  label={field.label}
                  variant="outlined"
                  fullWidth
                  required={field.required}
                />
              )}
            />
          </Grid>
        ))}

        {roleLevel === "Department" && (
          <Grid item xs={12} sm={6}>
            <Controller
              name="parentDepartmentId"
              control={control}
              render={({ field }) => (
                <TextField {...field} select label="Parent Department" variant="outlined" fullWidth>
                  {departmentList.map((department) => (
                    <MenuItem key={department.id} value={department.id}>
                      {department.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        )}

        {roleLevel === "Branch" && (
          <Grid item xs={12} sm={6}>
            <Controller
              name="parentBranchId"
              control={control}
              render={({ field }) => (
                <TextField {...field} select label="Parent Branch" variant="outlined" fullWidth>
                  {branchList.map((branch) => (
                    <MenuItem key={branch.id} value={branch.id}>
                      {branch.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <Controller
            name="isAdmin"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  select
                  {...field}
                  SelectProps={{
                    native: true,
                  }}
                  label="Is role of admin?"
                  InputLabelProps={{
                    shrink: true, // This forces the label to shrink
                  }}
                  value={field.value ? "Yes" : "No"}
                  onChange={(e) => field.onChange(e.target.value === "Yes")}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </TextField>
              </FormControl>
            )}
          />
        </Grid>

        {["Read", "Write", "Update", "Delete"].map((action) => (
          <Grid item xs={12} sm={6} key={action}>
            <Controller
              name={`permissions.${action.toLowerCase()}`}
              control={control}
              render={({ field }) => (
                <Autocomplete
                  multiple
                  options={permissionsOptions}
                  getOptionLabel={(option) => option}
                  value={field.value || []}
                  onChange={(_, newValue) => field.onChange(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label={`${action} Permissions`} fullWidth />
                  )}
                />
              )}
            />
          </Grid>
        ))}

        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <Button onClick={onCancel} variant="outlined" sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button className="ActionButtonsColor" type="submit" variant="contained" color="primary">
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateRole;
