import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button, Grid, Typography, MenuItem } from "@mui/material";
import axios from "axios";
import { backendUrl } from "utils/env";
import { token } from "utils/userToken";

const CreateDepartment = ({ onSave, onCancel, defaultValues }) => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: defaultValues || {
      departmentName: "",
      departmentCode: "",
      district: "",
      state: "",
      createdBy: "",
      city: "",
      parentDepartmentId: "",
    },
  });

  const onSubmit = (data) => {
    onSave(data); // Update parent state
    reset(); // Reset form
  };

  // Get department list
  const [departmentList, setDepartmentList] = useState([]);
  const getDepartments = async () => {
    const url = backendUrl + "/departments/get/dropdown";
    try {
      const response = await axios({
        method: "get",
        url,
        headers: { Authorization: `Bearer ${token}` },
      });
      setDepartmentList(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "20px" }}>
      <Grid container spacing={2}>
        {[
          { name: "departmentName", label: "Department Name", required: true },
          { name: "departmentCode", label: "Department Code", required: true },
          { name: "district", label: "District", required: true },
          { name: "state", label: "State", required: true },
          { name: "createdBy", label: "Created By", required: true },
          { name: "city", label: "City", required: true },
        ].map((fieldValue) => (
          <Grid item xs={12} sm={6} key={fieldValue.name}>
            <Controller
              name={fieldValue.name}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={fieldValue.label}
                  variant="outlined"
                  fullWidth
                  required={fieldValue.required}
                />
              )}
            />
          </Grid>
        ))}

        {/* Dropdown for Parent Department */}
        <Grid item xs={12} sm={6}>
          <Controller
            name="parentDepartmentId"
            control={control}
            render={({ field }) => (
              <TextField {...field} select label="Parent Department" variant="outlined" fullWidth>
                {departmentList.map((department) => (
                  <MenuItem key={department.id} value={department.id}>
                    {department.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <Button onClick={onCancel} variant="outlined" sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button className="ActionButtonsColor" type="submit" variant="contained" color="primary">
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateDepartment;
