import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  Box,
  TextField,
  Button,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Dialog,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  DialogTitle,
  DialogContent,
  Stack,
  TableBody,
  Card,
  CardContent,
  Chip,
} from "@mui/material";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Loader from "components/Loader";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { token } from "utils/userToken";

// const points = [
//   "Default in payment to the banks/sundry debtors and other statutory bodies.",
//   "Under insured or over insured inventory",
//   "Invoices devoid of TAN and other details",
//   "Dispute on title of the collateral securities",
//   // "Funds coming from other banks to liquidate the outstanding loan amount.",
//   "Request received from the borrower to postpone the inspection of the godown for flimsy reasons",
//   // "Financing the unit far away from the branch",
//   "Frequent invocation of BGs and development of LCs issued by other banks.",
//   // "Funding of the interest by sanctioning additional facilities",
//   "Same collateral charged to a number of lenders.",
//   "Concealment of certain vital documents like master agreement, insurance coverage.",
//   // "Large number of transactions with inter-connected companies and large outstanding from such companies.",
//   "Significant movements in inventory, disproportionately higher than the growth in turnover.",
//   "Significant movements in receivables, disproportionately higher than the growth in turnover and/or increase in ageing of the receivables.",
//   "Disproportionate increase in other current assets",
//   "Significant increase in working capital borrowings as percentage of turnover.",
//   "Critical issues highlighted in the stock audit report.",
//   "Increase in Fixed Assets, without corresponding increase in turnover (when project is implemented).",
//   "Increase in borrowings, despite huge cash and cash equivalents in the borrower's balance sheet.",
//   "Substantial related party transactions.",
//   "Poor disclosure of materially adverse information and no qualification by the statutory auditors.",
//   "Frequent change in accounting period and/or accounting policies.",
//   "Frequent request for general purpose loans.",
//   "Movement of an account from one bank to another.",
//   "Frequent ad hoc sanctions.",
//   // "Non-routing of sales proceeds through bank.",
//   "LCs issued for local trade/ related party transactions",
//   // "High value RTGS payment to unrelated parties.",
//   // "Heavy cash withdrawal in loan accounts.",
//   "Non submission of original bills.",
// ];

const points = [
  "Raid by Income tax/sales tax/central excise duty officials",
  "Frequent change in the scope of the project to be undertaken by the borrower",
  "Underinsured or overinsured inventory",
  "Invoices devoid of TAN and other details",
  "Dispute on the title of the collateral securities",
  "Costing of the project which is in wide variance with the standard cost of installation of the project",
  "Foreign bills remaining outstanding for a long time and tendency for bills to remain overdue",
  "Onerous clauses in the issue of BG/LC/standby letters of credit",
  "In merchanting trade, import leg not revealed to the bank",
  "Request received from the borrower to postpone the inspection of the godown for flimsy reasons",
  "Claims not acknowledged as debt high",
  "Same collateral charged to a number of lenders",
  "Concealment of certain vital documents like master agreement, insurance coverage",
  "Floating front/associate companies by investing borrowed money",
  "Reduction in the stake of promoter/director",
  "Resignation of key personnel and frequent changes in the management",
  "Substantial increase in unbilled revenue year after year",
  "Significant movements in inventory, disproportionately higher than the growth in turnover",
  "Significant movements in receivables, disproportionately higher than the growth in turnover and/or increase in ageing of the receivables",
  "Disproportionate increase in other current assets",
  "Critical issues highlighted in the stock audit report",
  "movement of an account from one bank to another",
  "Increase in Fixed Assets, without corresponding increase in turnover (when the project is implemented)",
  "Liabilities appearing in ROC search report, not reported by the borrower in its annual report",
  "Material discrepancies in the annual report",
  "Significant inconsistencies within the annual report (between various sections)",
  "Poor disclosure of materially adverse information and no qualification by the statutory auditors",
  "Frequent change in accounting period and/or accounting policies",
  "Frequent ad hoc sanctions",
  "Non-submission of original bills",
  "Presence of other un related bank’s name board",
  "Non-Cooperative attitude towards stock audit",
  "Request received from the borrower to post pone the stock audit",
  "Undue delay in submissions of financial statement for renewal of limits (delay of more than 3 months)",
  "Undue delay in implementation of the project",
];
const Compliance = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const route = useLocation().pathname.split("/").slice(1);
  const accountNumber = route[route.length - 1];

  const validationSchema = yup.object().shape({
    points: yup.array().of(
      yup.object().shape({
        decision: yup.string().required("Decision is required."),
        description: yup.string().when("decision", {
          is: "yes",
          then: (schema) => schema.required("Description is required when decision is 'Yes'"),
          otherwise: (schema) => schema.notRequired(),
        }),
        documentNames: yup.array().of(yup.string()).notRequired(),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      points: points.map((point, index) => ({
        pointNo: index + 1,
        point,
        decision: "no",
        description: "",
        documentNames: [],
      })),
      resolver: yupResolver(validationSchema),
    },
  });

  console.log(errors);
  const { fields } = useFieldArray({
    control,
    name: "points",
  });

  const [uploadedFiles, setUploadedFiles] = useState(points.map(() => []));
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [complianceHistory, setComplianceHistory] = useState([]);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleFileChange = (index, event) => {
    const files = Array.from(event.target.files);
    const updatedFiles = [...selectedFiles];
    updatedFiles[index] = [...(updatedFiles[index] || []), ...files];
    setSelectedFiles(updatedFiles);
  };
  const handleRemoveFile = (index, fileName) => {
    setUploadedFiles((prev) => {
      const updatedFiles = [...prev];
      updatedFiles[index] = updatedFiles[index].filter((file) => file.name !== fileName);
      return updatedFiles;
    });
  };
  const handleUploadFiles = async (index) => {
    const files = selectedFiles[index];
    if (!files || files.length === 0) {
      toast.error("Please select files before uploading.");
      return;
    }

    try {
      const url = `${backendUrl}/AlertOffline/${accountNumber}/${index + 1}/upload`;
      const formData = new FormData();
      files.forEach((file) => formData.append("files", file));

      const res = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        toast.success(res.data.message || `Files uploaded successfully for point ${index + 1}`);

        // Update uploadedFiles
        const updatedFiles = [...uploadedFiles];
        updatedFiles[index] = [...(updatedFiles[index] || []), ...files];
        setUploadedFiles(updatedFiles);

        // Update selected files
        const updatedSelectedFiles = [...selectedFiles];
        updatedSelectedFiles[index] = [];
        setSelectedFiles(updatedSelectedFiles);

        // Update documentNames in form data
        setValue(`points[${index}].documentNames`, [
          ...(watch(`points[${index}].documentNames`) || []),
          ...files.map((file) => file.name),
        ]);

        // Clear input
        document.querySelector(`#file-input-${index}`).value = null;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    }
  };

  const getComplianceHistory = async () => {
    try {
      const url = `${backendUrl}/AlertOffline/get/${accountNumber}`;
      const res = await axios({
        method: "get",
        url,
        headers: { Authorization: `Bearer ${token}` },
      });
      setComplianceHistory(res?.data?.data);
    } catch (error) {
      console.error(error?.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getComplianceHistory();
  }, []);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
  }));

  // submit
  const onSubmit = async (data) => {
    const url = backendUrl + `/AlertOffline/${accountNumber}/create`;
    const filteredPoints = data?.points?.filter((item) => item.decision === "yes");
    try {
      const res = await axios({
        url: url,
        method: "post",
        data: filteredPoints,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        toast.success(res.data.message || "Form submitted succsessfully");
        // reset form data
        getComplianceHistory();
        reset({
          points: points.map((point, index) => ({
            pointNo: index + 1,
            point,
            decision: "no",
            description: "",
            documentNames: [],
          })),
        });

        // reset uplaoded files
        setUploadedFiles(points.map(() => []));
        // close dialog
        setModalOpen(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox sx={{ mt: 3 }}>
          <Typography sx={{ marginLeft: "3px", textAlign: "center" }} variant="h4">
            Offline Alerts History
          </Typography>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 220px)",
              boxShadow: 0,
              border: "1px solid lightgray",
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ display: "contents" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    cursor: "pointer",
                    userSelect: "none",
                  }}
                >
                  <TableCell className="NewTableHeaderFontColor" sx={{ fontWeight: "700" }}>
                    Account No
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" sx={{ fontWeight: "700" }}>
                    Alert Description
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" sx={{ fontWeight: "700" }}>
                    Description
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" sx={{ fontWeight: "700" }}>
                    Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {complianceHistory.length ? (
                  complianceHistory.map((row) => (
                    <StyledTableRow key={row.id}>
                      <TableCell className="NewTableCells">{row.accountNumber || "---"}</TableCell>
                      <TableCell className="NewTableCells">{row.point || "---"}</TableCell>
                      <TableCell className="NewTableCells">{row.description || "---"}</TableCell>
                      <TableCell className="NewTableCells">
                        {row.createdTime
                          ? moment(row.createdTime).format("DD-MM-YYYY / hh:mm")
                          : "---"}
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell className="NewTableCells" colSpan={6} align="center">
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack alignItems="flex-end" my={1}>
            <Button
              variant="contained"
              className="ActionButtonsColor"
              onClick={() => setModalOpen(true)}
              sx={{ background: "#49a3f1", color: "white" }}
            >
              Record New
            </Button>
          </Stack>
        </MDBox>
      )}

      <Dialog open={modalOpen} maxWidth="md" fullWidth onClose={() => setModalOpen(false)}>
        <DialogTitle>RECORD NEW ALERT</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((item, index) => (
              <Card key={item.id} sx={{ marginBottom: 2, border: "1px solid lightgray" }}>
                <CardContent sx={{ padding: "20px" }}>
                  <Typography variant="h6">{`${index + 1}. ${points[index]}`}</Typography>

                  {/* Decision radio button */}
                  <Controller
                    name={`points[${index}].decision`}
                    control={control}
                    render={({ field }) => (
                      <FormControl error={!!errors?.points?.[index]?.decision}>
                        <RadioGroup row {...field}>
                          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                          <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                        <Typography variant="caption" color="error">
                          {errors?.points?.[index]?.decision && "Decision is required."}
                        </Typography>
                      </FormControl>
                    )}
                  />

                  {/* Description field */}
                  {watch(`points[${index}].decision`) === "yes" && (
                    <Box>
                      <Controller
                        name={`points[${index}].description`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Description"
                            fullWidth
                            multiline
                            rows={4}
                            error={!!errors?.points?.[index]?.description}
                            helperText={errors?.points?.[index]?.description?.message || ""}
                          />
                        )}
                      />

                      {/* File input */}
                      <TextField
                        type="file"
                        inputProps={{ multiple: true }}
                        id={`file-input-${index}`}
                        onChange={(e) => handleFileChange(index, e)}
                        fullWidth
                        sx={{ marginTop: 2 }}
                      />

                      <Button
                        variant="contained"
                        className="ActionButtonsColor"
                        sx={{ marginTop: 1 }}
                        onClick={() => handleUploadFiles(index)}
                      >
                        Upload
                      </Button>

                      {/* Uploaded files display */}
                      <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: 2 }}>
                        {uploadedFiles[index].map((file, fileIndex) => (
                          <Chip
                            key={file.id}
                            label={file.name}
                            onDelete={() => handleRemoveFile(index, file.name)}
                            sx={{ margin: 0.5 }}
                          />
                        ))}
                      </Box>
                    </Box>
                  )}
                </CardContent>
              </Card>
            ))}

            <Button className="ActionButtonsColor" type="submit" variant="contained" fullWidth>
              Submit
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
};

export default Compliance;
