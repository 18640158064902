import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { backendUrl } from "utils/env";
import { token } from "utils/userToken";

const CreateUser = ({ onSave, onCancel, defaultValues }) => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: defaultValues || {
      name: "",
      username: "",
      fullName: "",
      roleId: "",
      departmentId: "",
      branchId: "",
      email: "",
      number: "",
      userLevel: "department",
    },
  });

  const userLevel = watch("userLevel"); // Watch userLevel to conditionally show fields
  const branchId = watch("branchId");
  const departmentId = watch("departmentId");

  const onSubmit = (data) => {
    const { userLevel, ...submitData } = data;
    onSave(submitData);
    reset();
  };

  // Get department list
  const [departmentList, setDepartmentList] = useState([]);
  const getDepartments = async () => {
    const url = backendUrl + "/departments/get/dropdown";
    try {
      const response = await axios({
        method: "get",
        url,
        headers: { Authorization: `Bearer ${token}` },
      });
      setDepartmentList(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  const [branchList, setBranchList] = useState([]);
  const getBranches = async () => {
    const url = backendUrl + "/branches/get/dropdown";
    try {
      const response = await axios({
        method: "get",
        url,
        headers: { Authorization: `Bearer ${token}` },
      });
      setBranchList(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  const [roleList, setRoleList] = useState([]);
  const getRoleList = async () => {
    const url = backendUrl + `/roles/get/dropdown/${userLevel}?id=${departmentId || branchId}`;
    try {
      const response = await axios({
        method: "get",
        url,
        headers: { Authorization: `Bearer ${token}` },
      });
      setRoleList(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getRoleList();
    console.log("hey");
  }, [branchId, departmentId]);

  useEffect(() => {
    getDepartments();
    getBranches();
  }, []);

  useEffect(() => {
    if (userLevel === "department") {
      setValue("branchId", "");
    } else if (userLevel === "branch") {
      setValue("departmentId", "");
    }
  }, [userLevel]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "20px" }}>
      <Grid container spacing={2}>
        {/* User Level Dropdown */}
        <Grid item xs={12} sm={6}>
          <Controller
            name="userLevel"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="user-level-label">User Level</InputLabel>
                <Select {...field} labelId="user-level-label" label="User Level">
                  <MenuItem value="department">Department</MenuItem>
                  <MenuItem value="branch">Branch</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </Grid>

        {/* Conditional Fields for Department or Branch */}
        {userLevel === "department" && (
          <Grid item xs={12} sm={6}>
            <Controller
              name="departmentId"
              control={control}
              render={({ field }) => (
                <TextField {...field} select label="Select Department" variant="outlined" fullWidth>
                  {departmentList.map((department) => (
                    <MenuItem key={department.id} value={department.id}>
                      {department.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        )}
        {userLevel === "branch" && (
          <Grid item xs={12} sm={6}>
            <Controller
              name="branchId"
              control={control}
              render={({ field }) => (
                <TextField {...field} select label="Select Branch" variant="outlined" fullWidth>
                  {branchList.map((branch) => (
                    <MenuItem key={branch.id} value={branch.id}>
                      {branch.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <Controller
            name="roleId"
            control={control}
            render={({ field }) => (
              <TextField {...field} select label="Select Role" variant="outlined" fullWidth>
                {roleList.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        {[
          // { name: "roleId", label: "Role ID", required: true },
          { name: "name", label: "Name", required: true },
          { name: "username", label: "Username", required: true },
          { name: "fullName", label: "Full Name", required: true },
          { name: "email", label: "Email", required: true, type: "email" },
          { name: "number", label: "Phone Number", required: true, type: "tel" },
        ].map((field) => (
          <Grid item xs={12} sm={6} key={field.name}>
            <Controller
              name={field.name}
              control={control}
              render={({ field: inputField }) => (
                <TextField
                  {...inputField}
                  label={field.label}
                  type={field.type || "text"}
                  variant="outlined"
                  fullWidth
                  required={field.required}
                />
              )}
            />
          </Grid>
        ))}

        {/* Form Action Buttons */}
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <Button disabled={isSubmitting} onClick={onCancel} variant="outlined" sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            className="ActionButtonsColor"
            type="submit"
            variant="contained"
            color="primary"
          >
            {isSubmitting ? <CircularProgress size={20} /> : "Save"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateUser;
