import { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { styled } from "@mui/material/styles";

import {
  Typography,
  useMediaQuery,
  // Avatar,
  Box,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Card,
  Stack,
  TextField,
  CircularProgress,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  // List,
  // ListItem,
  // ListItemAvatar,
  // ListItemButton,
  // ListItemIcon,
  // ListItemText,
  // ListSubheader,
  // Paper,
  // TextField,
} from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import { IconEye, IconSquareLetterX } from "@tabler/icons-react";
import { token } from "utils/userToken";

const OfflineAlertsPieChart = ({ data, handleView, selectedBranch }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  // device state
  const mediumDevice = useMediaQuery("(max-width:767px)");
  // const titleText =
  //   "Point Intensity(Point failure map during selected duration)";

  const chartData =
    data?.filter((a) => a.count)?.map((a) => ({ name: a.point, value: a.count })) || [];

  const option = {
    title: {
      left: "center",
      text: `Offline alerts generated from system`,
    },
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        return `<div style="width: 33vw; white-space: pre-wrap; word-wrap: break-word;">${params.name} : ${params.value} (${params.percent}%)</div>`;
      },
    },
    legend: {
      show: false,
      top: "35%",
      left: "4%",
    },
    series: [
      {
        type: "pie",
        radius: mediumDevice ? ["40px", "120px"] : ["30%", "65%"],
        center: mediumDevice ? ["50%", "20%"] : ["50%", "50%"],
        data: chartData,
        itemStyle: {
          borderRadius: 5,
          borderColor: "#fff",
          borderWidth: 1,
        },
        label: {
          show: true,
          position: "outer",
          alignTo: "labelLine",
          bleedMargin: 5,
          fontSize: 16,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  // dialog

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountsList, setAccountsList] = useState([]);

  // Dialog Table
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const itemsPerPage = 20;

  useEffect(() => {
    setCurrentPage(1);
  }, [searchValue]);

  // sorting
  const renderSortArrow = (column) => {
    if (sortColumn === column) {
      return sortDirection === "asc" ? <span>&#9650;</span> : <span>&#9660;</span>;
    }
    return null;
  };

  const handleSort = (column) => {
    setCurrentPage(1);
    if (column === sortColumn) {
      // Toggle sort direction if clicking on the same column
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // Set sort column and reset sort direction
      setSortColumn(column);
      setSortDirection("asc");
    }
  };
  const sortedData = [...accountsList].map((item) => ({
    ...item,
    custName: item.custName.replace(/[^a-zA-Z ]/g, "").trim(),
    add4: item.add4.replace(/[^a-zA-Z ]/g, "").trim(),
  }));
  if (sortColumn) {
    sortedData.sort((a, b) => {
      const aValue = sortColumn === "outstand" ? parseFloat(a[sortColumn]) : a[sortColumn];
      const bValue = sortColumn === "outstand" ? parseFloat(b[sortColumn]) : b[sortColumn];
      if (sortDirection === "asc") {
        return sortColumn === "outstand" ? aValue - bValue : aValue.localeCompare(bValue);
      } else {
        return sortColumn === "outstand" ? bValue - aValue : bValue.localeCompare(aValue);
      }
    });
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredData = sortedData.filter(
    (item) =>
      item?.acctNo?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      item?.custName.toLowerCase().includes(searchValue?.toLowerCase())
  );

  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = filteredData.length > 0 ? Math.ceil(filteredData.length / itemsPerPage) : 1;

  const nextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
    // hide last border
  }));

  const handleClose = () => {
    setSearchValue("");
    setOpen(false);
    setAccountsList([]);
  };

  // Media query to adjust options for small devices
  if (window.innerWidth <= 768) {
    option.series[0].label.show = false; // Show labels
    option.legend.show = true; // Hide legend
  }
  const onChartClick = async (params) => {
    const point = params.data.name;
    setLoading(true);
    setOpen(true);
    try {
      const url = backendUrl + `/dashboard/accounts-by-point?point=${point}&&id=${selectedBranch}`;
      const res = await axios({
        method: "get",
        url: url,
        headers: { Authorization: `Bearer ${token}` },
      });
      setAccountsList(res?.data?.data || []);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onEvents = {
    click: onChartClick,
  };

  return (
    <>
      <Card className="NewCard" sx={{ p: 1 }}>
        <ReactEcharts
          option={option}
          style={{ height: mediumDevice ? "1000px" : "400px" }}
          onEvents={onEvents}
        />
      </Card>
      <Dialog
        PaperProps={{
          sx: {
            margin: "10px",
            width: {
              xs: "90%", // For extra small screens (mobile)
              sm: "90%", // For small screens (tablet)
              lg: "900px", // For large screens
            },
            minHeight: "600px",
            maxWidth: "100%", // Ensure max width is less than full screen to prevent scrollbars
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <IconButton onClick={handleClose} sx={{ position: "absolute", top: "10px", right: "10px" }}>
          <IconSquareLetterX />
        </IconButton>
        <DialogTitle>Accounts List</DialogTitle>
        <DialogContent>
          {loading ? (
            <Stack alignItems="center" height="450px" width="100%" justifyContent="center">
              <CircularProgress size={30} />
            </Stack>
          ) : (
            <MDBox sx={{ mt: 3 }}>
              <Stack sx={{ mb: 1 }} alignItems="flex-end">
                <TextField
                  value={searchValue}
                  disabled={!accountsList.length}
                  size="small"
                  sx={{ width: "200px" }}
                  label="Search"
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </Stack>

              <TableContainer
                sx={{
                  maxHeight: "calc(100vh - 300px)",
                  boxShadow: 0,
                  border: "1px solid lightgray",
                  overflow: "auto",
                }}
              >
                <Table sx={{ minWidth: "600px" }}>
                  <TableHead sx={{ display: "contents", userSelect: "none" }}>
                    <TableRow
                      className="NewTableHeader"
                      sx={{
                        backgroundColor: "#49a3f1",
                        color: "white !important",
                        position: "sticky",
                        top: 0,
                        zIndex: 999,
                        cursor: "pointer",
                      }}
                    >
                      <TableCell
                        className="NewTableHeaderFontColor"
                        align="left"
                        onClick={() => handleSort("acctNo")}
                      >
                        Account No {sortColumn === "acctNo" && renderSortArrow("acctNo")}
                      </TableCell>
                      <TableCell
                        className="NewTableHeaderFontColor"
                        align="left"
                        onClick={() => handleSort("custName")}
                      >
                        Account Holder {sortColumn === "custName" && renderSortArrow("custName")}
                      </TableCell>
                      <TableCell
                        className="NewTableHeaderFontColor"
                        align="left"
                        onClick={() => handleSort("telNo")}
                      >
                        Contact {sortColumn === "telNo" && renderSortArrow("telNo")}
                      </TableCell>
                      <TableCell
                        className="NewTableHeaderFontColor"
                        align="left"
                        onClick={() => handleSort("outstand")}
                      >
                        Outstand {sortColumn === "outstand" && renderSortArrow("outstand")}
                      </TableCell>
                      <TableCell
                        className="NewTableHeaderFontColor"
                        align="left"
                        onClick={() => handleSort("add4")}
                      >
                        Place {sortColumn === "add4" && renderSortArrow("add4")}
                      </TableCell>
                      <TableCell className="NewTableHeaderFontColor" align="left">
                        View
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentData.length ? (
                      currentData.map((row) => (
                        <StyledTableRow key={row.acctNo}>
                          <TableCell className="NewTableCells" align="left">
                            {row.acctNo}
                          </TableCell>
                          <TableCell className="NewTableCells" align="left">
                            {row.custName}
                          </TableCell>
                          <TableCell className="NewTableCells" align="left">
                            {row.telNo}
                          </TableCell>
                          <TableCell className="NewTableCells" align="left">
                            {row.outstand}
                          </TableCell>
                          <TableCell className="NewTableCells" align="left">
                            {row.add4}
                          </TableCell>
                          <TableCell className="NewTableCells" align="left">
                            <Button onClick={() => handleView(row.acctNo, row.newIrac)}>
                              <IconEye style={{ marginRight: "3px" }} />
                              View
                            </Button>
                          </TableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} sx={{ border: 0 }}>
                          No Data
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Stack direction="row" spacing={2} justifyContent="flex-end" m={1}>
                <Button onClick={prevPage} disabled={currentPage === 1}>
                  Previous
                </Button>
                <Typography>
                  {currentPage} / {totalPages}
                </Typography>
                <Button onClick={nextPage} disabled={currentPage === totalPages}>
                  Next
                </Button>
              </Stack>
            </MDBox>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OfflineAlertsPieChart;
